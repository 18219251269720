import React from 'react';
import DragAndDrop from '../fields/DragAndDrop';
import { EditOutlined } from '@ant-design/icons';
import {
    Typography,
    Input,
    DatePicker,
    Form,
} from 'antd';
import { updateReport } from '../../utils/update-report';
import FormModal from '../modals/FormModal';
import PropTypes from 'prop-types';
import constants from '../../static/constants.json';

const { NOTES } = constants.OBJECT_TYPES;

const ReleaseNotesForm = ({ setShouldUpdate, tableData }) => {
    const [ form ] = Form.useForm()

    const objectType = NOTES;

    const onSubmit = async () => {
        var values = await validateFormFields();
        form.resetFields();
        values.productName = tableData.productName;
        updateReport(values, tableData.objectId, objectType, setShouldUpdate);
    }

    const validateFormFields = async () => {
        try {
            return await form.validateFields();
        } catch (error) {
            console.error({FormValidation: "FAILED", message: error})
        }
    }

    const onClear = () => {
        form.resetFields();
    };

    const modalForm = {
        onSubmit: onSubmit,
        onClear: onClear,
        title: "Release Notes Form",
    }

    const openButton = {
        className: "icon-btn",
        type: "text",
        icon: <EditOutlined />,
        shape: "circle",
        tooltip: {
            title: "Enter Release Notes",
            placement: "topLeft"
        }
    }

    return (
        <>
            <FormModal modalForm={modalForm} openButton={openButton}>
                <Form
                    form={ form }
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Typography.Title level={5}>Product Name: { tableData.productName }</Typography.Title>
                    <Form.Item
                        name="releaseNumber"
                        label="Number"
                        rules={[
                            {
                                required: true,
                                message: "Release number required"
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="releaseDate"
                        label="Date"
                        rules={[
                            {
                                required: true,
                                message: "Release date required"
                            },
                        ]}
                    >
                        <DatePicker />
                    </Form.Item>
                    <Form.Item
                        name="releaseNotes"
                        label="Release Notes"
                        rules={[
                            {
                                required: true,
                                message: "Release notes PDF required"
                            },
                        ]}
                    >
                        <DragAndDrop
                            className="modal-element"
                            accept="application/pdf"
                            beforeUpload={() => false}
                        />
                    </Form.Item>
                </Form>
            </FormModal>
        </>
    );
}

ReleaseNotesForm.propTypes = {
    setShouldUpdate: PropTypes.func,
    tableData: PropTypes.object,
}

export default ReleaseNotesForm;