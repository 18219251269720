import {
    Button,
    Modal,
    Tooltip,
    Typography,
} from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const TOOLTIP_POP_DELAY = 0.3;

const FormModal = (props) => {
    const { modalForm, openButton } = props;

    const [isVisible, setIsVisible] = useState(false);

    const openModal = () => {
        setIsVisible(() => true);
        if (!!modalForm.onOpen) { modalForm.onOpen() }
    }

    const closeModal = () => {
        setIsVisible(() => false);
    }

    const handleOk = () => {
        modalForm.onSubmit();
        setTimeout(closeModal, 1000)
    }

    const handleCancel = () => {
        closeModal();
        modalForm.onClear();
    }

    const renderOpenButton = () => {
        if (!openButton.tooltip) {
            return (
                <Button
                    onClick={openModal}
                    className={openButton.className}
                    type={openButton.type}
                    icon={openButton.icon}
                    shape={openButton.shape}
                    style={openButton.style}
                    disabled={openButton.disabled}
                >
                    {openButton.text}
                </Button>
            )
        } else {
            return (
                <Tooltip
                    mouseEnterDelay={TOOLTIP_POP_DELAY}
                    title={openButton.tooltip.title}
                    placement={openButton.tooltip.placement}
                >
                    <Button
                        onClick={openModal}
                        className={openButton.className}
                        type={openButton.type}
                        icon={openButton.icon}
                        shape={openButton.shape}
                        style={openButton.style}
                        disabled={openButton.disabled}
                    >
                        {openButton.text}
                    </Button>
                </Tooltip>
            )
        }
    }
    const openModalButton = renderOpenButton();

    return (
        <>
            { openModalButton }
            <Modal
                okText="Submit"
                open={isVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                title={
                    <Typography.Title
                        level={4}
                        style={{
                            marginBottom: "0",
                        }}
                    >
                        {modalForm.title}
                    </Typography.Title>
                }
            >
                {props.children}
            </Modal>
        </>
    );
}

FormModal.defaultProps = {
    modalForm: {
        onOpen: () => "",
    }
}

FormModal.propTypes = {
    modalForm: PropTypes.object,
    openButton: PropTypes.object,
}

export default FormModal;