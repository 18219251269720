import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import PropTypes from 'prop-types';

const { Dragger } = Upload;

const DragAndDrop = (props) => {
    return (
        <Dragger
            { ...props }
            maxCount={1}
        >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area and click Upload</p>
        </Dragger>
    );
}

DragAndDrop.propTypes = {
    className: PropTypes.string,
    accept: PropTypes.string,
    beforeUpload: PropTypes.func,
}

export default DragAndDrop;