import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { getReports } from '../utils/reportUtils';
import { useLoading } from './useLoading';

/**
 * @name setShouldUpdate
 *
 * @callback updateCallback
 * @param {boolean} shouldUpdate
 */

/**
 * @function useData
 *
 * @param {string} objectType A label that helps filter data on the backend
 * @param {boolean} shouldUpdate A flag to tell the front end that new data has been uploaded
 * @param {updateCallback} setShouldUpdate A callback to update front end with new data from the backend
 * @returns {Array} An array that contains the loading state of the GET API call and the data object that returns from the GET API call
 * @description Hook to get a list of objects from the backend
 */
export const useData = (objectType, shouldUpdate, setShouldUpdate) => {
    const [data, setData] = useState();
    const loading = useLoading(data);

    const { user } = useContext(UserContext);
    const token = user.signInUserSession.accessToken.jwtToken;
    const uid = user.attributes.sub;
    console.log({token, uid});

    useEffect(() => {
        if (shouldUpdate && token) {
            getReports(objectType, uid)
                .then((reportList) => {
                    mapData(reportList);
                }).catch((err) => console.error({GETreports: "FAILURE", message: err}));
            setShouldUpdate(false);
        }

        const mapData = (reportList) => {
            if (!reportList) {
                return ;
            } else {
                if (Array.isArray(reportList.data)) {
                    setData(reportList.data.map((report, idx) => {
                        const dataItem = {
                            key: `${objectType}-dataItem-${idx}`,
                            objectId: report.id.split("#")[1],
                            objectType: report.id.split("#")[0],
                            ...report,
                        };

                        return dataItem;
                    }))
                }
            }
        }
    }, [objectType, setShouldUpdate, shouldUpdate, token, uid]);

    return [loading, data];
}