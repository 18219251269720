import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

const ArtifactsTable = ({ artifactTableProps }) => {
    return (
        <>
            <div>
                <Table
                    { ...artifactTableProps }
                />
            </div>
        </>
    );
}

ArtifactsTable.propTypes = {
    artifactTableProps: PropTypes.shape({
        columns: PropTypes.array.isRequired,
        dataSource: PropTypes.array,
        expandable: PropTypes.object,
        loading: PropTypes.bool,
        footer: PropTypes.func,
    }),
}

export default ArtifactsTable;