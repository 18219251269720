import { 
    Form,
    Input,
    DatePicker,
    Switch,
    Tooltip,
} from 'antd';
import React, { useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import DragAndDrop from '../fields/DragAndDrop';
import { updateReport } from '../../utils/update-report';
import FormModal from '../modals/FormModal';
import PropTypes from 'prop-types';
import constants from '../../static/constants.json';

const { VENDOR_MANAGEMENT } = constants.OBJECT_TYPES;

const AddProductForm = ({ setShouldUpdate, vendorId, vendorProducts }) => {
    const [form] = Form.useForm();
    const [addProductEvaluation, setAddProductEvaluation] = useState(false);
    const objectType = VENDOR_MANAGEMENT;

    const onSubmit = () => {
        form
            .validateFields()
            .then((formValues) => {
                form.resetFields();
                formValues.isNewProduct = true;
                formValues.evaluationTarget = "PRODUCT";
                formValues.vendorProducts = vendorProducts;
                formValues.hasProductEvaluation = addProductEvaluation;
                if (addProductEvaluation === true) { formValues.productEvaluationType = "Evaluation" }
                else { formValues.productEvaluationHistory = [] }
                console.log({formValues: formValues});
                updateReport(formValues, vendorId, objectType, setShouldUpdate)
                setShouldUpdate(true);
                setAddProductEvaluation(() => false);
            }
        )
    }

    const onClear = () => {
        form.resetFields();
        setAddProductEvaluation(() => false);
    }

    const modalForm = {
        onSubmit: onSubmit,
        onClear: onClear,
    }

    const openButton = {
        text: "Add Product"
    }

    return (
        <>
            <FormModal modalForm={modalForm} openButton={openButton}>
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Form.Item
                        name="productName"
                        label="Product Name"
                        rules={[
                            {
                                required: true,
                                message: "Product name required"
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="addProductEvaluationSwitch"
                        label="Add Product Evaluation"
                        initialValue={false}
                    >
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={false}
                            onChange={() => setAddProductEvaluation(currAddProductEvaluation => !currAddProductEvaluation)}
                            checked={addProductEvaluation}
                        />
                    </Form.Item>
                    {
                        addProductEvaluation !== true ? (
                            null
                        ) : (
                            <>
                                <Form.Item
                                    name="productEvaluationDate"
                                    label={`Product Evaluation Date`}
                                    rules={[
                                        {
                                            required: addProductEvaluation,
                                            message: `Evaluation date required`
                                        },
                                    ]}
                                >
                                    <DatePicker />
                                </Form.Item>
                                <Form.Item
                                    name="productEvaluationReport"
                                    label={`Evaluation Report`}
                                    rules={[
                                        {
                                            required: addProductEvaluation,
                                            message: `Evaluation report PDF required`
                                        },
                                    ]}
                                >
                                    <DragAndDrop
                                        className="modal-element"
                                        accept="application/pdf"
                                        beforeUpload={() => false}
                                    />
                                </Form.Item>
                                <Tooltip 
                                    title="You can change this later"
                                    mouseEnterDelay={0.5}
                                >
                                    <Form.Item
                                        name="nextProductEvaluationDate"
                                        label="Next Evaluation Date (Optional)"
                                    >
                                            <DatePicker />
                                    </Form.Item>
                                </Tooltip>
                            </>
                        )
                    }
                </Form>
            </FormModal>
        </>
    );
}

export default AddProductForm;

AddProductForm.propTypes = {
    setShouldUpdate: PropTypes.func,
    vendorId: PropTypes.string,
    vendorProducts: PropTypes.array,
}