import React from 'react';
import {
    Menu,
    Typography,
    Divider,
    Row,
    Col,
} from 'antd';
import PropTypes from 'prop-types';

const TabSider = (props) => {
    const { setCurrentSelection, siderTitle, menuItems, hasAddMenuItemButton, addMenuItemProps } = props;
    const handleMenuItemClick = (item) => {
        setCurrentSelection(() => parseInt(item.key.split("-")[2]))
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <Typography.Title level={4}>
                        { siderTitle }
                    </Typography.Title>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Menu
                        defaultSelectedKeys={['0']}
                        style={{
                            overflow: "auto",
                            maxHeight: "900px"
                        }}
                    >
                        <Menu.ItemGroup>
                        {
                            Array.isArray(menuItems) ? (
                                menuItems.map((menuItem, idx) => {
                                    return (
                                        <Menu.Item
                                            key={`menu-item-${idx}`}
                                            onClick={handleMenuItemClick}
                                        >
                                            { menuItem.name }
                                        </Menu.Item>
                                    );
                                })
                            ) : (
                                null
                            )
                        }
                        </Menu.ItemGroup>
                    </Menu>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                <Divider />
                {
                    !hasAddMenuItemButton ? (
                        null
                    ) : (
                        <>
                            {addMenuItemProps.buttonComponent}
                        </>
                    )
                }
                </Col>
            </Row>
        </>
    );
}

TabSider.propTypes = {
    setCurrentSelection: PropTypes.func,
    siderTitle: PropTypes.string,
    menuItems: PropTypes.array,
    hasAddMenuItemButton: PropTypes.bool,
    addMenuItemProps: PropTypes.object,
}

export default TabSider

