import React from 'react'
import { useParams } from 'react-router-dom';
import ErrorMessageView from '../components/errors/ErrorMessageView';

function ErrorPage() {
    const { errorCode } = useParams()

    return (
        <div>
            <ErrorMessageView errorCode={errorCode} />
        </div>
    )
}

export default ErrorPage;
