import { Popover, Space, Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import React from 'react'

const PopoverMenu = (props) => {
    const { listOptions, popoverPlacement, menuHeader, menuLabel } = props;

    const renderMenuList = () => {
        return (
            <Space direction="vertical" style={{ width: '100%' }}>
                {
                    listOptions.map((option, index) => {
                        return (
                            <div
                                key={`menu-option-${index}`}
                                style={{
                                    width: '100%',
                                    margin: '0px',
                                    padding: '9px 16px',
                                    background: 'white',
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.background = '#FAFAFA'
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.background = 'white'
                                }}
                            >
                                {option}
                            </div>
                        )
                    })
                }
            </Space>
        );
    }

    const content = renderMenuList();

    return (
        <Popover
            placement={popoverPlacement}
            content={content}
            title={menuHeader}
            zIndex={900}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "space-between"
                }}
            >
                <Typography.Link>{menuLabel}</Typography.Link>
                <RightOutlined style={{ marginLeft: '16px', color: "#1890ff" }} />
            </div>
        </Popover>
    );
}

export default PopoverMenu;