import axios from "axios";
import { TRUST_CENTER_DOWNLOAD_API, TRUST_CENTER_REPORT_API } from "../static/endpoints";
import { Auth } from "aws-amplify";

/**
 * @async
 * @function createReport This function sends data to the backend to create a new report
 * 
 * @param {FormData} formData A FormData object that contains the information to create a new report
 * @returns Response to axios post request.
 */
export const createReport = async (formData) => {
    console.log({CreatingReport: {
        FormData: formData
    }});
    const authToken = await getAuthToken();

    const request = {
        method: 'post',
        url: TRUST_CENTER_REPORT_API,
        headers: {
            Authorization: authToken
        },
        data: formData,
    }

    try {
        return await axios(request);
    } catch (error) {
        console.error(
            getErrorObject(request, { CreateReport: 'FAILED' }, error)
        )
    }
}

/**
 * @async
 * @function updateReport This function updates an existing report with new information
 * 
 * @param {string} itemId The id for the report being updated
 * @param {FormData} formData The information that the report get updated with
 */
export const updateReport = async (itemId, formData, hasReport) => {
    const authToken = await getAuthToken();
    itemId = itemId.includes("#") !== true ? itemId : itemId.split("#")[1];

    const request = {
        method: 'put',
        url: `${TRUST_CENTER_REPORT_API}/${itemId}/update`,
        headers: {
            Authorization: authToken,
        },
        data: formData,
    }
    if (hasReport === false) {
        request.url += "-no-file";
    }

    try {
        await axios(request);
    } catch (error) {
        console.error(
            getErrorObject(request, { UpdateReport: 'FAILED' }, error)
        );
    }
}

/**
 * @async
 * @function getReport This function gets the data needed to display a specific report for the end user
 * 
 * @param {object} reportIdentifiers An object that contains needed data to get a report
 *  @param {string} reportIdentifiers.reportId The unique id of the report
 *  @param {string} reportIdentifiers.reportType The type of report
 *  @param {string} reportIdentifiers.objectType The group that the report belongs to
 * @param {object} otherParams An object that contains any additional identifiers needed to get a certain report 
 *  Current otherParams options:
 *  @param {string} otherParams.preReleaseId An unique id that specifies a pre-release report in the 'notes' objects
 *  @param {string} otherParams.responseType The response type for the report response used particularly when getting a testing evidence report 
 * @returns The report object that gets used to display the report for the user.
 */
export const getReport = async (reportIdentifiers, otherParams) => {
    const { reportId, reportType, objectType } = reportIdentifiers;
    const { preReleaseId, sponsorCode, responseType } = otherParams;
    const authToken = await getAuthToken();

    const request = {
        method: 'get',
        url: `${TRUST_CENTER_REPORT_API}/${reportId}?type=${reportType}`,
        headers: {
            Authorization: authToken,
        },
        params: {
            objectType,
            preReleaseId,
            sponsorCode,
        }
    }
    if (responseType) {
        request.responseType = responseType;
    }

    try {
        return await axios(request);
    } catch (error) {
        console.error(
            getErrorObject(request, { GetReport: 'FAILED' }, error)
        );
    }
}

/**
 * @async
 * @function getReports This function gets a list of all the reports belonging to the specified objectType
 *
 * @param {string} objectType The group that the reports belong to
 * @param {string} uid (currently objectType = 'release' only) the unique user id that will be used to filter out specific unauthorized reports set by an admin
 * @returns A list of reports belonging to a particular objectType
 */
export const getReports = async (objectType, uid) => {
    const authToken = await getAuthToken();

    const request = {
        method: 'get',
        url: TRUST_CENTER_REPORT_API,
        headers: {
            Authorization: authToken,
        },
        params: {
            objectType,
            uid
        }
    }

    try {
        return await axios(request);
    } catch (error) {
        console.error(
            getErrorObject(request, { GetReports: 'FAILED' }, error)
        );
    }
}

/**
 * @async
 * @function downloadReport A function that creates a link that is used to download a report's zip file
 *
 * @param {object} params An object of necessary strings needed to download a specific report's zip file
 *  @param {string} params.product The product that the report represents
 *  @param {string} params.releaseNumber The release number for the report
 *  @param {string} params.s3Key The S3 object key used to get the zip file
 * @returns {HTMLLinkElement} A link ('a') element that will download the specified report's zip file
 */
export const downloadReport = async (params) => {
    const { product, releaseNumber, s3Key } = params;
    const authToken = await getAuthToken();

    let filename = `${product.replace(" ", "_")}_${releaseNumber}.zip`;
    const urlSearchParams = new URLSearchParams({
        s3Key,
        filename,
        auth: authToken
    })

    let href = `${TRUST_CENTER_DOWNLOAD_API}?${urlSearchParams}`;

    return getDownloadLink(filename, href)
}

const getAuthToken = async () => {
    const request = {};

    try {
        return (await Auth.currentSession()).getAccessToken().getJwtToken();
    } catch (error) {
        console.error(
            getErrorObject(request, { GetAuthToken: 'FAILED' }, error)
        )
    }
}

const getDownloadLink = (filename, href) => {
    const link = document.createElement('a');

    link.setAttribute('download', filename);
    link.setAttribute('href', href);

    return link;
}

const getErrorObject = (request, result, error) => {
    return {
        ...result,
        Request: request,
        Message: error,
        Stack: error.stack,
    }
}