import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import constants from '../static/constants.json';
import { getReport } from '../utils/reportUtils';
import sponsors from '../static/sponsors.json'

const {
    PRODUCT_RELEASE, IMS, ARTIFACT,
    NOTES, VENDOR_MANAGEMENT,
} = constants.OBJECT_TYPES;

const {
    SIGNED_RELEASE, TESTING_EVIDENCE, INSTALLATION_QUALIFICATION,
    IMS_DOCUMENT, ARTIFACT_DOCUMENT, PRERELEASE_NOTES,
    RELEASE_NOTES, VENDOR_EVALUATION_REPORT, PRODUCT_EVALUATION_REPORT,
} = constants.REPORT_TYPES;

const ReportView = () => {
    let { id: reportId, type: reportType } = useParams();
    const [html, setHtml] = useState();
    const [pdf, setPdf] = useState();
    const { user } = useContext(UserContext);
    let objectType;
    let reportIdExtra = "";
    if (reportId.indexOf('_') >= 0) {
        reportIdExtra = reportId.split("_")[1];
        reportId = reportId.split("_")[0];
    }

    let token = "";
    if (user) {
        token = user.signInUserSession.accessToken.jwtToken
    }

    switch (reportType.split("-")[0]) {
        case TESTING_EVIDENCE:
            objectType = PRODUCT_RELEASE;
            break;
        case SIGNED_RELEASE:
            objectType = PRODUCT_RELEASE;
            break;
        case INSTALLATION_QUALIFICATION:
            objectType = PRODUCT_RELEASE;
            break;
        case IMS_DOCUMENT:
            objectType = IMS;
            break;
        case ARTIFACT_DOCUMENT:
            objectType = ARTIFACT;
            break;
        case PRERELEASE_NOTES:
            objectType = NOTES;
            break;
        case RELEASE_NOTES:
            objectType = NOTES;
            break;
        case VENDOR_EVALUATION_REPORT:
            objectType = VENDOR_MANAGEMENT;
            break;
        case PRODUCT_EVALUATION_REPORT:
            objectType = VENDOR_MANAGEMENT;
            break;
        default:
            console.warn(`${reportType} not supported.`);
            break;
    }

    useEffect(() => {
        const reportIdentifiers = { reportId, reportType, objectType };
        const otherParams = {};
        if (reportIdExtra !== "") {
            if (!sponsors[reportIdExtra]) {
                otherParams.preReleaseId = reportIdExtra;
            } else {
                otherParams.sponsorCode = reportIdExtra;
            }
        }

        if (reportType !== TESTING_EVIDENCE) {
            otherParams.responseType = "blob";
        }

        if (token !== '' && token) {
            getReport(reportIdentifiers, otherParams)
                .then(response => {
                    if (reportType === TESTING_EVIDENCE) {
                        setHtml(() => response.data);
                    } else {
                        setPdf(URL.createObjectURL(response.data));
                    }
                })
                .catch(error => {
                    throw Error('Error getting report ' + error);
                })
            ;
        }
    }, [objectType, reportId, reportIdExtra, reportType, token, user]);

    return (
        <>
            {
                reportType === TESTING_EVIDENCE ? (
                    <iframe className="html-frame" srcDoc={html} title="Report View" height="100%" width="100%" />
                ) : (
                    <iframe className="pdf-frame" src={pdf} title="Report View" height="100%" width="100%" />
                )
            }
        </>
    );
}

export default ReportView;