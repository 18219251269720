import React, { useState } from 'react'
import {
    Button,
    Form,
    Input,
} from 'antd';
import TweenOne from 'rc-tween-one';
import { PlusOutlined } from '@ant-design/icons';

export const AddInput = (props) => {
    const { name, placeholder, addonBefore, addonAfter } = props;
    const [paused, setPaused] = useState(true);

    return (
        <Form.List
            name={name}
        >
            {
                (fields, { add, remove }, { errors }) => (
                    <>
                        {
                            fields.map((field) => (
                                <Form.Item
                                    style={{
                                        margin: "0 0 7px",
                                    }}
                                    required={false}
                                    key={field.key}
                                >
                                        <Button
                                            type="text"
                                            shape="circle"
                                            icon={
                                                <TweenOne
                                                    paused={paused}
                                                    animation={{
                                                        rotate: 45,
                                                        duration: 300,
                                                    }}
                                                >
                                                    <PlusOutlined />
                                                </TweenOne>
                                            }
                                            onClick={() => remove(field.name)}
                                        />
                                    <Form.Item
                                        { ...field }
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: 'Field may not be left blank.',
                                            },
                                        ]}
                                        noStyle
                                    >
                                        <Input
                                            placeholder={placeholder}
                                            addonBefore={addonBefore}
                                            addonAfter={addonAfter}
                                            style={{ maxWidth: '93.22%' }}
                                        />
                                    </Form.Item>
                                </Form.Item>
                            ))
                        }
                        <Form.Item>
                            <Button
                                type='text'
                                shape='circle'
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    add()
                                    setPaused(() => false)
                                }}
                            />
                        </Form.Item>
                    </>
                )
            }
        </Form.List>
    );
}