import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

const ProductReleaseTable = ({ productReleaseTableProps }) => {
    return (
        <div>
            <Table
                {...productReleaseTableProps}
            />
        </div>
    );
}

ProductReleaseTable.propTypes = {
    productReleaseTableProps: PropTypes.shape({
        columns: PropTypes.array.isRequired,
        dataSource: PropTypes.array,
        footer: PropTypes.func,
        loading: PropTypes.object,
        tableLayout: PropTypes.string,
    })
}

export default ProductReleaseTable;