import React, { useState, useContext, useEffect, useRef } from 'react';
import {
    Checkbox,
    Typography,
    Modal,
    Form,
    Button,
    Tooltip,
    Space,
} from 'antd';
import { NotificationOutlined } from '@ant-design/icons';
import { postSubscription } from '../../utils/post-subscription';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { UserContext } from '../../contexts/UserContext';
import { products } from '../../static/products';
import { TRUST_CENTER_GROUPS_API } from '../../static/endpoints';
import constants from '../../static/constants.json';

const {
    P1_NOTES_EMAILS,
    CP_NOTES_EMAILS,
    SU_NOTES_EMAILS,
 } = constants.GROUPS;

const ConfirmSubscriptionForm = () => {
    const [ form ] = Form.useForm()
    const [ visible, setVisible ] = useState(false);
    const { user } = useContext(UserContext);
    const checkboxValues = useRef([]);
    const [isFHVChecked, setIsFHVChecked] = useState()
    const [isFHCPChecked, setIsFHCPChecked] = useState()
    const [isFHSUChecked, setIsFHSUChecked] = useState()
    const [userGroups, setUserGroups] = useState();
    const [shouldGetGroups, setShouldGetGroups] = useState(false);
    const [shouldUpdateCheckbox, setShouldUpdateCheckbox] = useState(false)

    useEffect(() => {
        const getGroups = async (username) => {
            try {
                return await axios.get(TRUST_CENTER_GROUPS_API,
                    {
                        headers: {
                            "Authorization": (await Auth.currentSession()).getAccessToken().getJwtToken()
                        },
                        params: {
                            "username": username
                        }
                    }
                );
            } catch (err) {
                console.error({error: err});
            }
        }
        if (!shouldGetGroups) {
            getGroups(user.username)
                .then((res) => {
                    setUserGroups(res.data.groups);
                    setShouldUpdateCheckbox(true)
                }).catch((err) => {
                    console.error({error: err});
                })
            setShouldGetGroups(true);
        }
        return () => {
            console.log("Confirm Subscription Form Cleanup");
        }
    }, [shouldGetGroups, user.username]);

    useEffect(() => {
        if (shouldUpdateCheckbox) {
            if (userGroups.includes(P1_NOTES_EMAILS)) {
                setIsFHVChecked(true);
            }
            if (userGroups.includes(CP_NOTES_EMAILS)) {
                setIsFHCPChecked(true);
            }
            if (userGroups.includes(SU_NOTES_EMAILS)) {
                setIsFHSUChecked(true);
            }
        }
        return () => {
            console.log({cleanup: "cleaning up after updating checkboxes"});
        }
    }, [shouldUpdateCheckbox, userGroups])

    const openModal = () => {
        setVisible(true);
    };

    const closeModal = () => {
        setVisible(false);
    }

    const handleOk = () => {
        form
            .validateFields()
            .then(() => {
                let productInfo = [
                    {
                        name: products.flatironVessel.abbreviation,
                        subscriptionGroup: P1_NOTES_EMAILS,
                        checked: isFHVChecked,
                        changed: changed(products.flatironVessel.abbreviation)
                    },
                    {
                        name: products.flatironClinicalPipe.abbreviation,
                        subscriptionGroup: CP_NOTES_EMAILS,
                        checked: isFHCPChecked,
                        changed: changed(products.flatironClinicalPipe.abbreviation)
                    },
                    {
                        name: products.flatironSourceUpload.abbreviation,
                        subscriptionGroup: SU_NOTES_EMAILS,
                        checked: isFHSUChecked,
                        changed: changed(products.flatironSourceUpload.abbreviation)
                    }
                ];
                postSubscription(productInfo, user)
                    .then(() => {
                        setShouldUpdateCheckbox(true)
                    }).catch((err) => {
                        console.error({error: err})
                    })
                closeModal();
            })
            .catch((err) => {
                console.error('Form validation failed:', err);
            });
    };

    const handleCancel = () => {
        form.resetFields();
        checkboxValues.current = [];
        closeModal();
    };

    const changed = (product) => {
        switch (product) {
            case products.flatironVessel.abbreviation:
                if ((userGroups.includes(P1_NOTES_EMAILS) && isFHVChecked) || (!userGroups.includes(P1_NOTES_EMAILS) && !isFHVChecked)) {
                    return false;
                }
                return true;
            case products.flatironClinicalPipe.abbreviation:
                if ((userGroups.includes(CP_NOTES_EMAILS) && isFHCPChecked) || (!userGroups.includes(CP_NOTES_EMAILS) && !isFHCPChecked)) {
                    return false;
                }
                return true;
            case products.flatironSourceUpload.abbreviation:
                if ((userGroups.includes(SU_NOTES_EMAILS) && isFHSUChecked) || (!userGroups.includes(SU_NOTES_EMAILS) && !isFHSUChecked)) {
                    return false;
                }
                return true;
            default:
                break;
        }
    }

    return (
        <>
            <div className="notification-button" style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
                <Tooltip mouseEnterDelay={0.3} title="Email Notifications">
                    <Button type="primary" icon={<NotificationOutlined />} onClick={openModal}>
                        Get Notified
                    </Button>
                </Tooltip>
            </div>
            <Modal
                title={
                    <Space direction="vertical">
                        <Typography.Title level={4} style={{ marginBottom: 0 }}>Product Notes Subscriptions</Typography.Title>
                    </Space>
                }
                open={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Submit"
            >
                <Form
                    form={ form }
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Typography>Select which products to enable notifications for:</Typography>
                    <br />
                    <Form.Item name="subscribeToP1">
                        <Checkbox checked={isFHVChecked} onChange={() => setIsFHVChecked(!isFHVChecked)}>{products.flatironVessel.title}</Checkbox>
                    </Form.Item>
                    <Form.Item name="subscribeToCP">
                        <Checkbox checked={isFHCPChecked} onChange={() => setIsFHCPChecked(!isFHCPChecked)}>{products.flatironClinicalPipe.title}</Checkbox>
                    </Form.Item>
                    <Form.Item name="subscribeToSU">
                        <Checkbox checked={isFHSUChecked} onChange={() => setIsFHSUChecked(!isFHSUChecked)}>{products.flatironSourceUpload.title}</Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default ConfirmSubscriptionForm;