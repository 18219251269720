import VesselLogoColor from '../static/images/FlatironVessel_RGB.png';
import VesselLogoWhite from '../static/images/FlatironVessel_WhiteRGB.png';
import ClinicalPipeLogoColor from '../static/images/FlatironClinicalPipe.png';
import ClinicalPipeLogoWhite from '../static/images/FlatironClinicalPipe_WhiteRGB.png'

export const products = {
    totalProducts: 3,
    flatironVessel: {
        title: "Flatiron Vessel",
        abbreviation: "FHV",
        abbreviationElement: <abbr title="Flatiron Vessel">FHV</abbr>,
        initials: "fhv",
        logo: <img src={VesselLogoColor} alt="Flatiron Vessel Logo" />,
        whiteLogo: <img src={VesselLogoWhite} alt="Flatiron Vessel White Logo" />,
        primaryColor: "#3882f5",
    },
    flatironClinicalPipe: {
        title: "Flatiron Clinical Pipe",
        abbreviation: "FHCP",
        abbreviationElement: <abbr title="Flatiron Clinical Pipe">FHCP</abbr>,
        initials: "fhcp",
        logo: <img src={ClinicalPipeLogoColor} alt="Flatiron Clinical Pipe Logo" />,
        whiteLogo: <img src={ClinicalPipeLogoWhite} alt="Flatiron Clinical Pipe White Logo" />,
        primaryColor: "#5051DB",
    },
    flatironSourceUpload: {
        title: "Flatiron Source Upload",
        abbreviation: "FHSU",
        abbreviationElement: <abbr title="Flatiron Source Upload">FHSU</abbr>,
        initials: "fhsu",
        logo: undefined,
        whiteLogo: undefined,
        primaryColor: "#0a7387",
    },
}