import React, { useEffect, useState } from 'react';
import { Button, Table, Tag, Tooltip } from 'antd';
import { CheckCircleTwoTone, ExclamationCircleTwoTone, StopTwoTone, PlusOutlined } from '@ant-design/icons';
import AddUser from '../auth/AddUser';
import constants from '../../static/constants.json';
import Auth from '@aws-amplify/auth';
import axios from 'axios';
import { TRUST_CENTER_USER_API } from '../../static/endpoints';
import AccessLevelTag from '../tags/AccessLevelTag';
import DropdownMenu from '../menus/DropdownMenu';

const { IMS_GROUP, ARTIFACT_GROUP } = constants.GROUPS;

const TOOLTIP_POP_DELAY = 0.1;

const UserTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [shouldFetch, setShouldFetch] = useState(true);
    let accessLevelKeyCount = 0;

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(() => true);

            let params = {
                headers: {
                    Authorization: (await Auth.currentSession()).getAccessToken().getJwtToken()
                },
            };

            let userData = (await axios.get(TRUST_CENTER_USER_API, params)).data;

            setData(userData);
            setLoading(() => false);
        }

        if (shouldFetch) {
            fetchUsers();
            setShouldFetch(false);
        }
    }, [shouldFetch]);

    const getAccessIcon = (accessLevel, reportAccess) => {
        const { imsAccess, artifactAccess } = reportAccess;

        switch (accessLevel) {
            case IMS_GROUP:
                if (imsAccess === "PARTIAL") {
                    return (
                        <Tooltip
                            title={`This user has partial access to ${accessLevel} reports`}
                            trigger={['click', 'hover']}
                            mouseLeaveDelay={TOOLTIP_POP_DELAY}
                            mouseEnterDelay={10}
                        >
                            <ExclamationCircleTwoTone style={{ cursor: "help" }} twoToneColor="#F0541E" />
                        </Tooltip>
                    )
                } else if (imsAccess === "NONE") {
                    return (
                        <Tooltip
                            title={`This user has no access to ${accessLevel} reports`}
                            trigger={['click', 'hover']}
                            mouseLeaveDelay={TOOLTIP_POP_DELAY}
                            mouseEnterDelay={10}
                        >
                            <StopTwoTone style={{ cursor: "help" }} twoToneColor="#CF000F" />
                        </Tooltip>
                    );
                }
                return <CheckCircleTwoTone twoToneColor="#009944" />
            case ARTIFACT_GROUP:
                if (artifactAccess === "PARTIAL") {
                    return (
                        <Tooltip
                            title={`This user has partial access to ${accessLevel} reports`}
                            trigger={['click', 'hover']}
                            mouseLeaveDelay={TOOLTIP_POP_DELAY}
                            mouseEnterDelay={10}
                        >
                            <ExclamationCircleTwoTone style={{ cursor: "help" }} twoToneColor="#F0541E" />
                        </Tooltip>
                    )
                } else if (artifactAccess === "NONE") {
                    return (
                        <Tooltip
                            title={`This user has no access to ${accessLevel} reports`}
                            trigger={['click', 'hover']}
                            mouseLeaveDelay={TOOLTIP_POP_DELAY}
                            mouseEnterDelay={10}
                        >
                            <StopTwoTone style={{ cursor: "help" }} twoToneColor="#CF000F" />
                        </Tooltip>
                    );
                }

                return (
                    <Tooltip
                        title={`This user has full access to ${accessLevel} reports`}
                        trigger={['click', 'hover']}
                        mouseLeaveDelay={TOOLTIP_POP_DELAY}
                        mouseEnterDelay={10}
                    >
                        <CheckCircleTwoTone style={{ cursor: "help" }} twoToneColor="#009944" />
                    </Tooltip>
                );
            default:
                return (
                    <Tooltip
                        title={`This user has full access to ${accessLevel} reports`}
                        trigger={['click', 'hover']}
                        mouseLeaveDelay={TOOLTIP_POP_DELAY}
                        mouseEnterDelay={10}
                    >
                        <CheckCircleTwoTone style={{ cursor: "help" }} twoToneColor="#009944" />
                    </Tooltip>
                );
        }
    }

    const columns = [
        {
            title: 'Email Address',
            dataIndex: 'emailAddress',
            key: 'emailAddress',
            width: "25%",
        },
        {
            title: 'Access Level',
            dataIndex: 'accessLevels',
            key: 'accessLevels',
            width: "55%",
            render: (accessLevels, renderData) => {
                const { emailAddress: username } = renderData;
                return (
                    <div
                        key={`access-level-cell-${accessLevelKeyCount++}`}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                        }}
                    >
                        {
                            accessLevels.map((accessLevel) => {
                                let reportAccessIcon = getAccessIcon(accessLevel, renderData.reportAccess);

                                return (
                                    <AccessLevelTag
                                        tagKey={`tag-${accessLevel}`}
                                        reportAccessIcon={reportAccessIcon}
                                        closeAccessTitle={`Remove ${accessLevel} access from ${username}`}
                                        changeAccessTitle={`Change user access to ${accessLevel} reports`}
                                        accessLevel={accessLevel}
                                        username={username}
                                        setShouldFetch={setShouldFetch}
                                    />
                                );
                            })
                        }
                        <DropdownMenu
                            accessLevels={accessLevels}
                            itemKey={renderData.key}
                            username={username}
                            setShouldFetch={setShouldFetch}
                        >
                            <Button
                                icon={<PlusOutlined style={{ opacity: "50%" }} />}
                                size="small"
                                key={"add-access-level" + renderData.key}
                                style={{
                                    margin: "4px",
                                    paddingLeft: "8px",
                                    paddingRight: "8px",
                                    width: "auto"
                                }}
                            />
                        </DropdownMenu>
                    </div>
                );
            }
        },
        {
            title: 'Subscriptions',
            dataIndex: 'subscriptions',
            key: 'subscriptions',
            render: (subscriptions) => (
                <>
                    {
                        subscriptions.map((subscription) => {
                            let subscriptionColor = 'volcano';
                            return (
                                <Tag color={ subscriptionColor } key={ subscription } style={{ width: "auto" }}>
                                    { subscription }
                                </Tag>
                            );
                        })
                    }
                </>
            )
        }
    ];

    const footer = () => (
        <>
            <div>
                <AddUser setShouldFetch={setShouldFetch} />
            </div>
        </>
    );

    return (
        <div className="user-table">
            <Table
                footer={footer}
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{ position: ['bottomRight'] }}
                scroll={{ y: "20rem" }}
                bordered
            />
        </div>
    );
}

export default UserTable;