import { Button, Modal, Space, Typography } from 'antd'
import React, {  useState } from 'react';
import { memoryDisplay } from '../../utils/memory-display';
import { downloadReport } from '../../utils/reportUtils';

function ReportDownloader(props) {
    const { viewText, downloadParams, hasOneReport, objectId } = props;
    const [visible, setVisible] = useState(false);

    const openConfirmModal = () => {
        setVisible(() => true);
    }

    const closeConfirmModal = () => {
        setVisible(() => false);
    }

    const handleOk = async () => {
        let downloadLink = await downloadReport(downloadParams);

        document.body.appendChild(downloadLink)
        downloadLink.click();
        document.body.removeChild(downloadLink);
        closeConfirmModal();
    }

    const handleCancel = () => {
        closeConfirmModal();
    }

    return (
        <div id={objectId}>
            <Button
                type="link"
                onClick={openConfirmModal}
                style={{ width: '100%', padding: "0" }}
            >
                <Space style={{ width: '100%' }} direction="horizontal" align='center' size='large'>
                    {
                        hasOneReport === true ? (
                            viewText
                        ) : (
                            downloadParams.sponsorName
                        )
                    }
                    <Typography
                        style={{
                            opacity: "60%",
                            fontSize: "9pt",
                        }}
                    >
                        ({memoryDisplay(downloadParams.contentLength, 2)})
                    </Typography>
                </Space>
            </Button>
            <Modal
                open={visible}
                okText="Yes"
                cancelText="No"
                title="Confirm Download"
                okType="link"
                okButtonProps={{
                    download: true,
                    onClick: () => handleOk()
                }}
                onCancel={handleCancel}
            >
                <Typography>
                    Due to the size of the requested download and/or internet speed, this may take a few minutes. Confirm?
                </Typography>
            </Modal>
        </div>
    )
}

export default ReportDownloader;
