import React from 'react';
import PropTypes from 'prop-types';

/**
 * ReportLink creates a link that opens a new tab to display a report
 *
 * @component
 * @example
 * const id = "1xXX11XX1x1xXxxXXXxxXX1Xxxx"
 * const type = "signedRelease"
 * const text = "View"
 *
 * return (
 *   <a
 *      href="<window location origin>/report/1xXX11XX1x1xXxxXXXxxXX1Xxxx/signedRelease"
 *      target="_blank"
 *      rel="noreferrer"
 *   >
 *      View
 *   </a>
 * )
 *
 * @param {Object} props The properties of the ReportLink
 * @param {string} props.id The report ID
 * @param {string} props.type The type of report
 * @param {string} props.text The link's display text
 * @param {string} props.keyid The key for the report link
 *
 * @returns {HTMLLinkElement} Link element to the report pdf
 */
const ReportLink = ({id, type, text, keyid}) => {
    const linkProps = {
        keyid,
        href: `${window.location.origin}/report/${id}/${type}`,
        target: "_blank",
        rel: "noreferrer",
    }
    return (
        <a
            { ...linkProps }
        >
            {text}
        </a>
    )
}

ReportLink.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
}

export default ReportLink;
