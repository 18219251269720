import React, { useState } from 'react';
import { Tooltip, Typography } from 'antd';
import { FilterFilled } from '@ant-design/icons';
import { useData } from '../../lib/useData';
import ReleaseNotesTable from '../tables/ReleaseNotesTable';
import { dateConverter } from '../../utils/dateConverter';
import ReleaseNotesForm from '../forms/ReleaseNotesForm';
import ConfirmSubscriptionForm from '../forms/ConfirmSubscriptionForm';
import NotesForm from '../forms/NotesForm';
import { columnCreator } from '../../utils/column-creator';
import { products } from '../../static/products';
import ReportLink from '../navigation/ReportLink';
import PropTypes from 'prop-types';
import constants from '../../static/constants.json';
import { useAuthenticator } from '@aws-amplify/ui-react';

const { NOTES } = constants.OBJECT_TYPES;

const ReleaseNotesTab = ({isAdmin, groups, setGroups}) => {
    const [shouldUpdate, setShouldUpdate] = useState(true);
    const objectType = NOTES;
    const { route: authRoute } = useAuthenticator(context => [context.route])

    const [loading, dataSource] = useData(objectType, shouldUpdate, setShouldUpdate);

    const columnProps = {
        titles: ["Product Name", "Pre-Release", "Release"],
        dataIndexes: ["productName", "preRelease", "release"],
        keys: ["productName", "preRelease", "release"],
        widths: ["20%", "40%", "40%"],
    }

    const productNameFilters = [
        {
            text: products.flatironVessel.title,
            value: products.flatironVessel.title,
        },
        {
            text: products.flatironClinicalPipe.title,
            value: products.flatironClinicalPipe.title,
        },
        {
            text: products.flatironSourceUpload.title,
            value: products.flatironSourceUpload.title,
        },
        { text: "Protocol First", value: "Protocol First" },
        { text: "Clinical Pipe", value: "Clinical Pipe" },
        { text: "Source Upload", value: "Source Upload" }
    ]

    const preReleaseChildrenColumnProps = {
        titles: ["Number", "Date", "Notes"],
        dataIndexes: ["preReleaseNumber", "preReleaseDate", "preReleaseNotes"],
        keys: ["preReleaseNumber", "preReleaseDate", "preReleaseNotes"],
        widths: `${TABLE_LENGTH / NUMBER_OF_COLUMNS / NUMBER_OF_SUB_COLUMNS_PRE_RELEASE}%`,
    }

    const releaseChildrenColumnProps = {
        titles: ["Number", "Date", "Notes"],
        dataIndexes: ["releaseNumber", "releaseDate", "releaseNotes"],
        keys: ["releaseNumber", "releaseDate", "releaseNotes"],
        widths: `${TABLE_LENGTH / NUMBER_OF_COLUMNS / NUMBER_OF_SUB_COLUMNS_RELEASE}%`,
    }

    const childrenRender = (child, noItem) => {
        if (!child.value) {
            return (
                <Typography
                    style={
                        noItem === "N/A" ? { paddingLeft: "16px" } : {}
                    }
                >
                    {noItem}
                </Typography>
            )
        } else {
            return <Typography>{child.item}</Typography>
        }
    }
    const preReleaseNotesLink = (tableData) => {
        if (tableData.preReleaseNotes?.s3Key === "") {
            return <Typography style={{ paddingLeft: "16px" }}>N/A</Typography>
        }
        return (
            <ReportLink
                id={tableData.objectId}
                type="preReleaseNotes"
                text="View"
                keyid={tableData.objectId}
            />
        );
    }

    const releaseNotesLink = (releaseNotes, tableData) => {
        return (
            <ReportLink
                id={tableData.objectId}
                type="releaseNotes"
                text="View"
                keyid={tableData.objectId}
            />
        );
    }

    const renderReleaseNotes = (releaseNotes, tableData) => {
        if ((!releaseNotes || releaseNotes?.s3Key === "") && authRoute === "authenticated" && isAdmin) {
            return (
                <ReleaseNotesForm tableData={tableData} setShouldUpdate={setShouldUpdate} />
            );
        } else {
            return releaseNotesLink(releaseNotes, tableData);
        }
    }

    const columns = columnCreator(columnProps);

    columns[PRODUCT_NAME_INDEX].filterIcon = () => (<Tooltip placement="topLeft" title="Filter Product"><FilterFilled style={{ fontSize: "15px" }} /></Tooltip>);
    columns[PRODUCT_NAME_INDEX].filters = productNameFilters;
    columns[PRODUCT_NAME_INDEX].onFilter = (productName, record) => record.productName.indexOf(productName) === 0;

    columns[PRE_RELEASE_INDEX].children = columnCreator(preReleaseChildrenColumnProps);
    columns[PRE_RELEASE_INDEX].children[NUMBER_INDEX].render = (preReleaseNumber) => childrenRender({value: preReleaseNumber, item: preReleaseNumber}, "--");
    columns[PRE_RELEASE_INDEX].children[DATE_INDEX].render = (preReleaseDate) => childrenRender({value: preReleaseDate, item: dateConverter(preReleaseDate)}, "--");
    columns[PRE_RELEASE_INDEX].children[NOTES_INDEX].render = (preReleaseNotes, tableData) => childrenRender({value: preReleaseNotes, item: preReleaseNotesLink(tableData)}, "N/A");

    columns[RELEASE_INDEX].children = columnCreator(releaseChildrenColumnProps);
    columns[RELEASE_INDEX].children[NUMBER_INDEX].render = (releaseNumber) => childrenRender({value: releaseNumber, item: releaseNumber}, "");
    columns[RELEASE_INDEX].children[DATE_INDEX].render = (releaseDate) => childrenRender({value: releaseDate, item: dateConverter(releaseDate)}, "");
    columns[RELEASE_INDEX].children[NOTES_INDEX].render = (releaseNotes, tableData) => renderReleaseNotes(releaseNotes, tableData);

    const expandable = {
        indentSize: 20,
    }

    const footer = () => {
        return (
            <div>
                <ConfirmSubscriptionForm
                    setShouldUpdate={setShouldUpdate}
                    groups={groups}
                    setGroups={setGroups}
                />
                {
                    authRoute === "authenticated" && isAdmin ? (
                        <NotesForm
                            setShouldUpdate={setShouldUpdate}
                        />
                    ) : null
                }
            </div>
        );
    }

    const releaseNotesTableProps = {
        columns,
        dataSource,
        expandable,
        loading,
        footer,
    }

    return (
        <ReleaseNotesTable
            releaseNotesTableProps={releaseNotesTableProps}
        />
    )
}

ReleaseNotesTab.propTypes = {
    isAdmin: PropTypes.bool,
    groups: PropTypes.array,
    setGroups: PropTypes.func,
}

export default ReleaseNotesTab;

const
    PRODUCT_NAME_INDEX = 0,
    PRE_RELEASE_INDEX = 1,
    RELEASE_INDEX = 2,
    NUMBER_INDEX = 0,
    DATE_INDEX = 1,
    NOTES_INDEX = 2,
    NUMBER_OF_COLUMNS = 3,
    NUMBER_OF_SUB_COLUMNS_PRE_RELEASE = 3,
    NUMBER_OF_SUB_COLUMNS_RELEASE = 3,
    TABLE_LENGTH = 100;