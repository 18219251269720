import React, { useState } from 'react'
import FormModal from '../modals/FormModal'
import { UserAddOutlined } from '@ant-design/icons';
import {
    Checkbox,
    Form,
    Input,
} from 'antd';
import { createUser } from '../../utils/create-user';
import FieldGrouper from '../fields/FieldGrouper';

function AddUser({ setShouldFetch }) {
    const [form] = Form.useForm();
    const [admin, setAdmin] = useState(false);
    const [productRelease, setProductRelease] = useState(false);
    const [ims, setIms] = useState(false);
    const [artifact, setArtifact] = useState(false);
    const [notes, setNotes] = useState(false);
    const [vendor, setVendor] = useState(false);

    const onSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                values = {
                    ...values,
                    admin,
                    productRelease,
                    ims,
                    artifact,
                    notes,
                    vendor,
                }
                form.resetFields();
                resetChecks();
                createUser(values);
                setShouldFetch(() => true);
            })
    }

    const onClear = () => {
        form.resetFields();
        resetChecks();
        console.log({FormCleared: "SUCCESS"});
    }

    const resetChecks = () => {
        setAdmin(() => false);
        setProductRelease(() => false);
        setIms(() => false);
        setArtifact(() => false);
        setNotes(() => false);
        setVendor(() => false);
    }

    const openButton = {
        text: "Add User",
        icon: <UserAddOutlined />
    }

    const modalForm = {
        title: "Add User",
        onSubmit: onSubmit,
        onClear: onClear,
    }

    return (
        <div>
            <FormModal openButton={openButton} modalForm={modalForm}>
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            {
                                required: true,
                                message: "Email required"
                            }
                        ]}
                    >
                        <Input
                            type="email"
                            placeholder="Enter email here..."
                        />
                    </Form.Item>
                    <FieldGrouper
                        fieldsetStyle={{
                            border: "1px solid #d9d9d9",
                            paddingLeft: "20px"
                        }}
                        legendStyle={{
                            width: '125px',
                            padding: "5px 10px",
                            margin: "0 20px",
                            borderBottom: "0",
                            fontSize: "14px",
                        }}
                        title="Access Levels"
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                padding: "10px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    width: "50%",
                                }}
                            >
                                <Form.Item
                                    name="admin"
                                >
                                    <Checkbox
                                        checked={admin}
                                        style={{
                                            lineHeight: '32px',
                                        }}
                                        onChange={(event) => {
                                            console.log({Event: event});
                                            if (event.target.checked === true) {
                                                setProductRelease(() => false);
                                                setIms(() => false);
                                                setArtifact(() => false);
                                                setNotes(() => false);
                                                setVendor(() => false);
                                            }
                                            setAdmin(currentValue => !currentValue)
                                        }}
                                    >
                                        Admin
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item
                                    name="productRelease"
                                >
                                    <Checkbox
                                        checked={productRelease}
                                        style={{
                                            lineHeight: '32px',
                                        }}
                                        disabled={admin}
                                        onChange={() => setProductRelease(currentValue => !currentValue)}
                                    >
                                        Product Release 
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item
                                    name="ims"
                                >
                                    <Checkbox
                                        checked={ims}
                                        style={{
                                            lineHeight: '32px',
                                        }}
                                        disabled={admin}
                                        onChange={ () => setIms(currentValue => !currentValue)}
                                    >
                                        IMS 
                                    </Checkbox>
                                </Form.Item>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        width: "50%",
                                    }}
                                >
                                <Form.Item
                                    name="artifact"
                                >
                                    <Checkbox
                                        checked={artifact}
                                        style={{
                                            lineHeight: '32px',
                                        }}
                                        disabled={admin}
                                        onChange={() => setArtifact(currentValue => !currentValue)}
                                    >
                                        Artifacts
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item
                                    name="notes"
                                >
                                    <Checkbox
                                        checked={notes}
                                        style={{
                                            lineHeight: '32px',
                                        }}
                                        disabled={admin}
                                        onChange={() => setNotes(currentValue => !currentValue)}
                                    >
                                        Notes 
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item
                                    name="vendor"
                                >
                                    <Checkbox
                                        checked={vendor}
                                        style={{
                                            lineHeight: '32px',
                                        }}
                                        disabled={admin}
                                        onChange={() => setVendor(currentValue => !currentValue)}
                                    >
                                        Vendor
                                    </Checkbox>
                                </Form.Item>
                            </div>
                        </div>
                    </FieldGrouper>
                </Form>
            </FormModal>
        </div>
    )
}

export default AddUser
