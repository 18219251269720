import './css/styles.css';
import awsconfig from './aws-exports';
import 'antd/dist/antd.min.css';
import { Amplify } from 'aws-amplify';
import React, { useEffect, useMemo, useRef, useState } from 'react';
// import { AmplifyAuthContainer, AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import {
  Authenticator,
  Button,
  Heading,
  View,
  useAuthenticator,
  useTheme
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import RouteSwitch from './components/navigation/RouteSwitch';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import SignOutIdleTimer from './components/utilities/SignOutIdleTimer';
import { UserContext } from './contexts/UserContext';
import { GroupsContext } from './contexts/GroupsContext';

Amplify.configure(awsconfig);

const App = () => {
  const components = {
    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            level={3}
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          >
            Sign in to your account
          </Heading>
        )
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toResetPassword}
              size="medium"
              variation="link"
            >
              Reset Password
            </Button>
          </View>
        );
      },
    }
  }
  const { route: authRoute } = useAuthenticator(context => [context.route]);
  const { user, signOut } = useAuthenticator(context => [context.user]);
  const [ groups, setGroups ] = useState([]);
  const pageHider = useRef(null);

  const userValue = useMemo(() => ({ user }), [user]);
  const groupsValue = useMemo(() => ({ groups, setGroups }), [groups, setGroups]);

  useEffect(() => {
    pageHider.current.hidden = true;
    const isSignedIn = () => {
      if (authRoute === "authenticated" && user) {
        pageHider.current.hidden = false;
        if (user.signInUserSession.idToken.payload["cognito:groups"] !== undefined) {
          setGroups(user.signInUserSession.idToken.payload["cognito:groups"]);
        }
      }
      return authRoute === "authenticated" && user;
    }

    isSignedIn();
  }, [authRoute, user]);

  useEffect(() => {
    if (authRoute === "authenticated") {
      pageHider.current.hidden = false;
    }
    else {
      pageHider.current.hidden = true;
    }
  })

  return (
    <div className="App">
      <Router>
        <div className="site-page-header-ghost-wrapper">
          <UserContext.Provider value={ userValue }>
            <GroupsContext.Provider value={ groupsValue }>
              <Header />
            </GroupsContext.Provider>
          </UserContext.Provider>
        </div>
        <SignOutIdleTimer signOut={ signOut } />
        <Authenticator
          components={components}
          loginMechanisms={["email"]}
          hideSignUp={true}
        >
        </Authenticator>
        <UserContext.Provider value={ userValue }>
          <GroupsContext.Provider value={ groupsValue }>
            <RouteSwitch />
          </GroupsContext.Provider>
        </UserContext.Provider>
      </Router>

      <div ref={pageHider}>
        <Footer />
      </div>
    </div>
  );
}

export default App;

