import { Auth, API } from 'aws-amplify';

/**
 * Adds a specified user to a Cognito group
 *
 * @async
 * @function addUserToGroup
 *
 * @param {string} username Username of the user being added to a group
 * @param {string} groupname The group name that the user is being added to
 * @returns {Promise} Promise represents the response to the addUserToGroup request
 */
export async function addUserToGroup(username, groupname) {
    const apiName = 'AdminQueries';
    const path = '/addUserToGroup';
    const request = {
        body: {
            "username" : username,
            "groupname" : groupname
        },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.post(apiName, path, request);
};

/**
 * Removes a specified user to a Cognito group
 *
 * @async
 * @function removeUserFromGroup
 *
 * @param {string} username Username of the user being removed from a group
 * @param {string} groupname The group name that the user is being removed from
 * @returns {Promise} Promise represents the response to the removeUserFromGroup request
 */
export async function removeUserFromGroup(username, groupname) {
    const apiName = 'AdminQueries';
    const path = '/removeUserFromGroup';
    const request = {
        body: {
            "username" : username,
            "groupname" : groupname,
        },
        headers: {
            'Content-Type' : 'application/json',
            Authorization : `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.post(apiName, path, request);
};

/**
 * Lists all users in a Cognito userpool
 *
 * @async
 * @function listUsers
 * @returns {Promise} Promise represents the response to the listUsers request. The list of users may be accessed through the returned objects "data" property
 */
export async function listUsers() {
    const apiName = 'AdminQueries';
    const path = '/listUsers';
    const request = {
        queryStringParameters: { },
        headers: {
            'Content-Type' : 'application/json',
            Authorization : `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.get(apiName, path, request);
};

/**
 * Disables a user from using any read/write privileges in a Cognito userpool
 * @async
 * @function disableUser
 *
 * @param {string} username The username of the specified user
 * @returns {Promise} Promise represents the response the the disableUser function
 */
export async function disableUser(username) {
    const apiName = 'AdminQueries';
    const path = '/disableUser';
    const request = {
        body: {
            "username" : username
        },
        headers: {
            'Content-Type' : 'application/json',
            Authorization : `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.post(apiName, path, request);
};

/**
 * Gets a user's information for a Cognito userpool
 * @async
 * @function getUser
 *
 * @param {string} username The username of the specified user 
 * @returns {Promise} Promise that represents the response to the GET API call. User information can be accessed through the "data" property of the response object.
 */
export async function getUser(username) {
    const apiName = 'AdminQueries';
    const path = '/getUser';
    const request = {
        queryStringParameters: {
            "username" : username
        },
        headers: {
            'Content-Type' : 'application/json',
            Authorization : `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.get(apiName, path, request);
};

/**
 * Gets a list of all the groups that the specified user belongs to.
 * @async
 * @function listGroupsForUser
 *
 * @param {string} username The username of the specified user
 * @returns {Promise} Promise that represents the response to the listGroupsForUser request. The list of groups the user belongs to can be accessed through the "data" property of the response object.
 */
export async function listGroupsForUser(username) {
    const apiName = 'AdminQueries';
    const path = '/listGroupsForUser';
    const request = {
        queryStringParameters: {
            "username" : username
        },
        headers: {
            'Content-Type' : 'application/json',
            Authorization : `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.get(apiName, path, request);
};


// TODO
// export async function enableUser() {

// };



// export async function listGroups() {

// };


// export async function listUsersInGroup() {

// };

// export async function signUserOut() {

// };