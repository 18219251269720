import React from 'react';
import { Button, Result } from 'antd';
import { rootPath } from '../../static/routes';
import PropTypes from 'prop-types';
import { ArrowLeftOutlined } from '@ant-design/icons';
import constants from '../../static/constants.json';

const { CONTACT_SUPPORT_SITE } = constants.URLS;

function ErrorMessageView({ errorCode }) {
    var errorMsg;

    switch (errorCode) {
        case "timeout":
            errorMsg = {
                status: "error",
                title: "408",
                subTitle: "You have been idle for too long and have been logged out.",
                extra: <div>
                    <Button
                        icon={<ArrowLeftOutlined />}
                        type="link"
                        href={rootPath}
                    >
                        Sign In
                    </Button>
                </div>,
            }
            break;
        case "401":
            errorMsg = {
                status: "error",
                title: "401",
                subTitle: "You are not authorized to view this resource.",
                extra: <div>
                    <Button
                        icon={<ArrowLeftOutlined />}
                        type="link"
                        href={rootPath}
                    >
                        Try Again
                    </Button>
                    <Button
                        type="link"
                        href={CONTACT_SUPPORT_SITE}
                    >
                        Contact Us
                    </Button>
                </div>,
            }
            break;
        default:
            errorMsg = {
                status: "error",
                title: "An error has occured!",
                extra: <div>
                    <Button
                        icon={<ArrowLeftOutlined />}
                        type="link"
                        href={rootPath}
                    >
                        Back
                    </Button>
                </div>,
            }
            break;
    }
    return (
        <div>
            <Result
                { ...errorMsg }
                className="error-result"
            />
        </div>
    );
}

export default ErrorMessageView;

ErrorMessageView.propTypes = {
    errorCode: PropTypes.string,
};
