import React, { useState } from 'react';
import {
    Input,
    Select,
    DatePicker,
    Form,
    AutoComplete,
} from 'antd';
import DragAndDrop from '../fields/DragAndDrop';
import { products } from '../../static/products';
import { useSubmitForm } from '../../lib/useSubmitForm';
import FormModal from '../modals/FormModal';
import PropTypes from 'prop-types';
import constants from '../../static/constants.json';
import { AddInput } from '../fields/AddInput';

const { PRODUCT_RELEASE } = constants.OBJECT_TYPES;

const { Option } = Select;

const productOptions = [
    products.flatironVessel.title,
    products.flatironClinicalPipe.title,
    products.flatironSourceUpload.title,
];

const ProductReleaseForm = ({ setShouldUpdate }) => {
    const [form] = Form.useForm();
    const [possibleHtml, setPossibleHtml] = useState("");
    const [canAddInput, setCanAddInput] = useState(false)
    const [addInputMargin, setAddInputMargin] = useState();
    const objectType = PRODUCT_RELEASE;
    let hookParams = {
        form,
        objectType,
        setShouldUpdate,
    }

    const [setShouldSubmitForm] = useSubmitForm(hookParams);

    const onSubmit = () => {
        setShouldSubmitForm(() => true);
    };

    const onClear = () => {
        form.resetFields();
    };

    const renderOptions = (options) => {
        return (
            options.map((option, idx) => {
                return <Option key={ option + idx } value={ option }>{ option }</Option>
            })
        );
    }

    const handleProductChange = (option) => {
        if (option === products.flatironClinicalPipe.title) {
            setAddInputMargin(() => "0 0 7px");
            setCanAddInput(() => true);
        } else {
            setAddInputMargin(() => undefined);
            setCanAddInput(() => false);
        }
    }

    const openButton = {
        text: "Add Product Release",
    }

    const modalForm = {
        title: "Product Release Form",
        onSubmit,
        onClear,
    }

    return (
        <>
            <FormModal openButton={openButton} modalForm={modalForm}>
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Form.Item
                        name="product"
                        label="Product"
                        rules={[
                            {
                                required: true,
                                message: 'Product name required'
                            },
                        ]}
                    >
                        <Select onChange={handleProductChange}>
                            { renderOptions(productOptions) }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="releaseNumber"
                        label="Release Number"
                        rules={[
                            {
                                required: true,
                                message: 'Release number required'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="releaseDate"
                        label="Release Date"
                        rules={[
                            {
                                required: true,
                                message: 'Release date required'
                            },
                        ]}
                    >
                        <DatePicker />
                    </Form.Item>
                    <Form.Item
                        name="signedRelease"
                        label="Signed Release Report"
                        rules={[
                            {
                                required: true,
                                message: 'Signed release PDF required'
                            },
                        ]}
                    >
                        <DragAndDrop
                            className="modal-element"
                            accept="application/pdf"
                            beforeUpload={(data) => {
                                data.arrayBuffer()
                                    .then((data) => {
                                        const buf = Buffer.from(data).toString('utf-8');
                                        var urlRegex = /\bhttps:\S+html\b/g;
                                        setPossibleHtml(buf.match(urlRegex));
                                    }).catch((err) => {
                                        console.error("error creating array buffer:", err)
                                    });
                                return false;
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="testingReport"
                        label="Testing Evidence Link"
                        rules={[
                            {
                                required: true,
                                message: 'Testing evidence link required'
                            },
                        ]}
                        style={{
                            margin: addInputMargin ,
                        }}
                    >
                        <AutoComplete
                            options={[{ label: possibleHtml, value: possibleHtml }]} 
                        />
                    </Form.Item>
                    {
                        canAddInput !== true ? null : (
                            <AddInput
                                name='sponsorTestingReports'
                                placeholder="Sponsor HTML link"
                            />
                        )
                    }
                    <Form.Item
                        name="installationQualification"
                        label="Installation Qualification"
                    >
                        <DragAndDrop
                            className="modal-element"
                            accept="application/pdf"
                            beforeUpload={() => {
                                return false;
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="zipFilename"
                        label="Zip File Name"
                        style={{
                            margin: addInputMargin,
                        }}
                    >
                        <Input
                            addonBefore="artifact-zip/"
                            addonAfter=".zip"
                        />
                    </Form.Item>
                    {
                        canAddInput !== true ? null : (
                            <AddInput
                                name='sponsorZipFilenames'
                                placeholder='Sponsor zip file name'
                                addonBefore='artifact-zip/'
                                addonAfter='.zip'
                            />
                        )
                    }
                </Form>
            </FormModal>
        </>
    );
}

ProductReleaseForm.propTypes = {
    setShouldUpdate: PropTypes.func,
}

export default ProductReleaseForm;