import React from 'react';
import {
    Form,
    DatePicker,
} from 'antd';
import { updateReport } from '../../utils/update-report';
import FormModal from '../modals/FormModal';
import constants from '../../static/constants.json';

const { VENDOR_MANAGEMENT } = constants.OBJECT_TYPES;

const ChangeDateOfServiceForm = (props) => {
    const {
        objectId,
        setShouldUpdate,
        openModalIcon
    } = props;
    const [form] = Form.useForm();

    const objectType = VENDOR_MANAGEMENT;

    const onOpen = () => {
        console.log({objectId: objectId});
    }

    const onSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                form.resetFields();
                values.isDateOfServiceChange = true;
                values.evaluationTarget = 'VENDOR';
                updateReport(values, objectId, objectType, setShouldUpdate);
            })
            .catch((err) => {
                console.error({FormValidation: "FAILURE", message: err});
            })
    }

    const onClear = () => {
        form.resetFields();
    }

    const createFormItem = () => {
        return (
            <Form.Item
                name="dateOfService"
            >
                <DatePicker />
            </Form.Item>
        );
    }

    const formItem = createFormItem();

    const modalForm = {
        onSubmit: onSubmit,
        onClear: onClear,
        onOpen: onOpen,
        title: "Update Vendor Date of Service",
    }

    const openButton = {
        className: "icon-btn",
        type: "text",
        icon: openModalIcon,
        shape: "circle",
        tooltip: {
            title: "Edit Date of Service",
        }
    }

    return (
        <>
            <FormModal modalForm={modalForm} openButton={openButton}>
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    { formItem }
                </Form>
            </FormModal>
        </>
    );
}

export default ChangeDateOfServiceForm;