import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

const VendorProductTable = (props) => {
    const { columns, dataSource, loading, hasAddProductButton, addProductButton } = props;
    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            size='small'
            footer={() =>
                <div>
                    <div style={{ margin: "5px" }}>
                        {
                            !hasAddProductButton ? (
                                null
                            ) : (
                                addProductButton
                            )
                        }
                    </div>
                </div>
            }
        />
    );
}

VendorProductTable.propTypes = {
    columns: PropTypes.array.isRequired,
    dataSource: PropTypes.array,
    loading: PropTypes.bool,
    hasAddProductButton: PropTypes.bool,
    addProductButton: PropTypes.node,
}

export default VendorProductTable;