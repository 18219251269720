import { useEffect, useState } from "react";
import { useData } from "../../lib/useData";
import ReportLink from "../navigation/ReportLink";
import ImsTable from "../tables/ImsTable";
import { FilterFilled } from "@ant-design/icons";
import { Tooltip, Typography } from 'antd';
import { columnCreator } from "../../utils/column-creator";
import ImsDocumentForm from '../forms/ImsDocumentForm';
import { dateConverter } from "../../utils/dateConverter";
import PropTypes from 'prop-types';
import constants from '../../static/constants.json';
import ImsAddNewVersionForm from "../forms/ImsAddNewVersionForm";
import { useAuthenticator } from "@aws-amplify/ui-react";

const ImsTab = ({ isAdmin }) => {
    const [shouldUpdate, setShouldUpdate] = useState(true);
    const { route: authRoute } = useAuthenticator(context => [context.route])
    const objectType = constants.OBJECT_TYPES.IMS;
    const reportType = constants.REPORT_TYPES.IMS_DOCUMENT;
    let columns;

    const [loading, dataSource] = useData(objectType, shouldUpdate, setShouldUpdate);

    useEffect(() => {
        if (Array.isArray(dataSource)) {
            dataSource.forEach(item => {
                if (Array.isArray(item.children) && item.children.length <= 0) {
                    delete item.children;
                }
            });
        }
    }, [dataSource]);

    let columnProps = {
        titles: ["Name", "Document Type", "Effective Date", "Version"],
        dataIndexes: ["name", "documentType", "effectiveDate", "version"],
        keys: ["name", "documentType", "effectiveDate", "version"],
        widths: `${100 / NUMBER_OF_COLUMNS}%`,
    }

    let filters = DOCUMENT_TYPES.map((type) => {return {text: type.title, value: type.title}});


    const nameRender = (name, tableData) => {
        if (!tableData.imsDocument) {
            return <p>N/A</p>
        } else {
            return <ReportLink
                    id={tableData.objectId}
                    type={reportType}
                    text={name}
                    keyid={tableData.objectId}
                />
        }
    }

    const filterIcon = () =>  (<Tooltip placement="topLeft" title="Filter Product"><FilterFilled style={{ fontSize: "15px" }} /></Tooltip>);
    const onFilter = (documentType, record) => record.documentType.indexOf(documentType) === 0;

    columns = columnCreator(columnProps);

    columns[NAME].render = (name, tableData) => nameRender(name, tableData);

    columns[DOCUMENT_TYPE].filterIcon = filterIcon();
    columns[DOCUMENT_TYPE].filters = filters;
    columns[DOCUMENT_TYPE].onFilter = (documentType, record) => onFilter(documentType, record);

    columns[EFFECTIVE_DATE].render = (effectiveDate) => dateConverter(effectiveDate);

    columns[VERSION].render = (version, tableData) => (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }}
        >
            <Typography
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {version}
            </Typography>
            {
                authRoute === "authenticated" && isAdmin ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        {
                            tableData.children || tableData.children === null ? (
                                <ImsAddNewVersionForm setShouldUpdate={setShouldUpdate} tableData={tableData} />
                            ) : (
                                <div style={{ width: "32px" }} />
                            )
                        }
                    </div>
                ) : (
                    null
                )
            }
        </div>
    );

    const expandable = {
        indentSize: 20,
    }

    const footer = () => {
        return (
            <div>
                {
                    authRoute === "authenticated" && isAdmin
                    ? (
                        <ImsDocumentForm
                            setShouldUpdate={setShouldUpdate}
                            names={() => {
                                var names = dataSource.map((row, idx) => {
                                    return {
                                        key: row.name + idx,
                                        label: row.name,
                                        value: row.name,
                                        documentType: row.documentType
                                    }
                                })
                                return names;
                            }}
                        />
                    ) : null
                }
            </div>
        );
    }
    const imsTableProps = {
        columns: columns,
        dataSource: dataSource,
        expandable: expandable,
        loading,
        footer: footer,
    }

    return (
        <ImsTable
            imsTableProps={imsTableProps}
        />
    );
}

ImsTab.propTypes = {
    isAdmin: PropTypes.bool,
}

export default ImsTab;

const DOCUMENT_TYPES = [
    {title: "Policy"},
    {title: "SOP"},
    {title: "Work Instruction"},
];
const
    NAME = 0,
    DOCUMENT_TYPE = 1,
    EFFECTIVE_DATE = 2,
    VERSION = 3,
    NUMBER_OF_COLUMNS = 4;