import React, { useContext, useState } from 'react';
import constants from '../../static/constants.json';
import { Button, Modal, Skeleton, Table, Tooltip } from 'antd';
import { UserContext } from '../../contexts/UserContext';
import { columnCreator } from '../../utils/column-creator';
import { fetchUserAccess, updateUserAccess } from '../../utils/user-access-tools';
import { getUser } from '../../utils/admin-utils';
import { getReports } from '../../utils/reportUtils';

const { IMS_GROUP, ARTIFACT_GROUP } = constants.GROUPS;
const { IMS, ARTIFACT } = constants.OBJECT_TYPES;

const COLUMN_WIDTHS = 50;

const ReportAccessModifierForm = ({ accessLevel, username, setShouldFetch }) => {
    const [columns, setColumns] = useState();
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [data, setData] = useState();
    const [visible, setVisible] = useState(false);
    const [accessType, setAccessType] = useState("");
    const [loading, setLoading] = useState(false);

    const { user } = useContext(UserContext);
    const token = user.signInUserSession.accessToken.jwtToken;
    const disabled = accessLevel === IMS_GROUP || accessLevel === ARTIFACT_GROUP ? false : true

    const onOpen = async (event) => {
        let tempAccessType = getAccessType(event.target.textContent);
        setAccessType(() => getAccessType(event.target.textContent));
        setLoading(() => true)

        setVisible(() => true);
        try {
            await getData(tempAccessType);
            const user = await getUser(username);
            const userAccess = (await fetchUserAccess(user, token)).data;
            const { imsAccessList, artifactAccessList } = userAccess;

            switch (tempAccessType) {
                case IMS:
                    setSelectedKeys(() => imsAccessList);
                    break;
                case ARTIFACT:
                    setSelectedKeys(() => artifactAccessList);
                    break;
                default:
                    break;
            }
            setLoading(() => false);
        } catch (error) {
            console.error({GetData: "FAILED", Message: error});
        }
    }

    const onSubmit = () => {
        let listSelection = selectedKeys;
        if (Array.isArray(selectedKeys) && Array.isArray(data) && selectedKeys.length === data.length) {
            listSelection = null;
        }
        getUser(username)
            .then(async (userToUpdate) => {
                await updateUserAccess(userToUpdate, listSelection, accessType, token);
            })
            .then(() => {
                setShouldFetch(() => true);
            })
            .catch(error => {
                console.error({GetUser: "FAILED", Message: error});
            })
        setVisible(() => false);
    };

    const onClear = () => {
        setVisible(() => false);
    };

    const createColumns = (accessLevel) => {
        switch (accessLevel) {
            case IMS_GROUP:
                return columnCreator({
                    keys: ["name", "documentType"],
                    titles: ["Document Name", "Document Type"],
                    dataIndexes: ["name", "documentType"],
                    widths: `${COLUMN_WIDTHS}`,
                });
            case ARTIFACT_GROUP:
                return columnCreator({
                    keys: ["name", "department"],
                    titles: ["Name", "Department"],
                    dataIndexes: ["name", "department"],
                    widths: `${COLUMN_WIDTHS}`,
                });
            default:
                break;
        }
    }

    const getTableData = async (objectType) => {
        try {
            const reportList = await getReports(objectType);
            const tempData = reportList.data;

            tempData.forEach(report => {
                report.key = report.versionId;
                if (report.children) {
                    delete report.children;
                }
            })
            setData(() => tempData);
        } catch (error) {
            console.error({Error: error});
        }
    }

    const getTableColumns = () => {
        const tempColumns = createColumns(accessLevel)
        setColumns(() => tempColumns);
    }

    const getData = async (tempAccessType) => {
        switch (tempAccessType) {
            case IMS:
                await getTableData(IMS);
                getTableColumns();
                break;
            case ARTIFACT:
                await getTableData(ARTIFACT);
                getTableColumns();
                break;
            default:
                break;
        }
    }

    const onSelectChange = (selectedRowKeys, type) => {
        setSelectedKeys(() => selectedRowKeys);
    }

    const tableData = {
        key: `${accessLevel}-table`,
        columns,
        dataSource: data,
        expandable: { indentSize: 20 },
        rowSelection: {
            selectedRowKeys: selectedKeys,
            onChange: (selectedRowKeys) => onSelectChange(selectedRowKeys),
        },
    }

    const getAccessType = (group) => {
        switch (group) {
            case IMS_GROUP:
                return IMS;
            case ARTIFACT_GROUP:
                return ARTIFACT;
            default:
                break;
        }
    }

    return (
        <div>
            {
                disabled !== true ? (
                    <Tooltip title={"Change user access to " + accessLevel + " reports"}>
                        <Button
                            type="text"
                            block={true}
                            size="small"
                            onClick={onOpen}
                        >
                            { accessLevel }
                        </Button>
                    </Tooltip>
                ) : (
                    <Button
                        type="text"
                        block={true}
                        size="small"
                    >
                        { accessLevel }
                    </Button>
                )
            }
            <Modal
                title={`Report Access for ${username}`}
                onOk={onSubmit}
                okText="Submit"
                onCancel={onClear}
                open={visible}
                width="33%"
                style={{
                    maxWidth: "100%",
                }}
            >
                {
                    loading === true ? (
                        <Skeleton active />
                    ) : (
                        <Table {...tableData} />
                    )
                }
            </Modal>
        </div>
    );
}

export default ReportAccessModifierForm;