import { useAuthenticator } from '@aws-amplify/ui-react';
import { Button } from 'antd';

const SignOut = () => {
    const { signOut } = useAuthenticator(context => [context.user]);
    return (
        <Button
            onClick={() => signOut()}
            size="sm"
            className="primary-btn"
        >
            Sign Out
        </Button>
    )
}

export default SignOut;