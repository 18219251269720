export const rootPath = "/";
export const reportsPath = "/reports";
export const reportsTabPath = "/reports/:reportType";
export const SignedInRootPath = (tab) => "/reports/" + tab;

export const usersTabPath = "/users";

export const showReportPath = "/report/:id/:type";

export const errorPath = "/error";
export const timeoutPath = "/timeout";
export const errorCodePath = "/error/:errorCode";
