import React, { useState, useEffect } from 'react';
import {
    Col,
    Divider,
    Row,
    Skeleton,
    Tooltip,
    Typography,
} from 'antd';
import VendorDescription from '../descriptions/VendorDescription';
import AddProductEvaluationForm from '../forms/AddProductEvaluationForm';
import AddProductForm from '../forms/AddProductForm';
import AddVendorEvaluationForm from '../forms/AddVendorEvaluationForm';
import AddVendorForm from '../forms/AddVendorForm';
import TabSider from '../layout/TabSider';
import EvaluationHistoryModal from '../modals/EvaluationHistoryModal';
import VendorProductTable from '../tables/VendorProductTable';
import { EditOutlined } from '@ant-design/icons';
import ChangeNextEvaluationDateForm from '../forms/ChangeNextEvaluationDateForm';
import { dateConverter } from '../../utils/dateConverter';
import { useData } from '../../lib/useData';
import ReportLink from '../navigation/ReportLink';
import PropTypes from 'prop-types';
import ChangeDateOfServiceForm from '../forms/ChangeDateOfServiceForm';
import constants from '../../static/constants.json';

const {
    VENDOR_NAME, DATE_OF_SERVICE, VENDOR_EVALUATION_HISTORY,
    LAST_VENDOR_EVALUATION_DATE, LAST_VENDOR_EVALUATION,
    NEXT_VENDOR_EVALUATION_DATE, OBJECT_ID, TYPENAME, VENDOR_PRODUCTS,
} = constants.VENDOR_DATA;
const { VENDOR_EVALUATION_REPORT, PRODUCT_EVALUATION_REPORT } = constants.REPORT_TYPES;
const TOOLTIP_POP_DELAY = 0.5;

const VendorTab = ({ isAdmin }) => {
    const [shouldUpdate, setShouldUpdate] = useState(true);
    const [currentVendorIndex, setCurrentVendorIndex] = useState(0);
    const [siderMenuItems, setSiderMenuItems] = useState([]);
    const [hasVendors, setHasVendors] = useState(false);
    const objectType = constants.OBJECT_TYPES.VENDOR_MANAGEMENT;

    const [loading, data] = useData(objectType, shouldUpdate, setShouldUpdate);

    useEffect(() => {
        const siderItemList = [];

        if (Array.isArray(data) && data.length > 0) {
            setHasVendors(true);
            data.forEach((vendor) => {
                siderItemList.push({
                    name: vendor.vendorName
                });
            })
        }

        setSiderMenuItems(() => siderItemList);
    }, [data]);

    const calcMarginLeft = () => {
        if (isAdmin === false) {
            return "0px";
        }
        return "32px";
    }
    const marginLeft = calcMarginLeft();
    const renderNextEvaluationDescription = () => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}
            >
                <div
                    style={{
                        marginLeft: marginLeft,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Typography>{getCurrentVendorData(NEXT_VENDOR_EVALUATION_DATE, currentVendorIndex)}</Typography>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    {
                        hasVendors !== true || isAdmin === false ? (
                            null
                        ) : (
                            <Tooltip placement="right" mouseEnterDelay={TOOLTIP_POP_DELAY} title="Edit next evaluation date">
                                <ChangeNextEvaluationDateForm
                                    vendorName={getCurrentVendorData(VENDOR_NAME, currentVendorIndex)}
                                    setShouldUpdate={setShouldUpdate}
                                    objectId={getCurrentVendorData(OBJECT_ID, currentVendorIndex)}
                                    openModalIcon={<EditOutlined />}
                                    evaluationTarget="VENDOR"
                                />
                            </Tooltip>
                        )
                    }
                </div>
            </div>
        );
    }

    const renderDateOfService = () => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}
            >
                <div
                    style={{
                        marginLeft: marginLeft,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Typography>{ !dateConverter(getCurrentVendorData(DATE_OF_SERVICE, currentVendorIndex)) ? "--" : dateConverter(getCurrentVendorData("DATE_OF_SERVICE", currentVendorIndex)) }</Typography>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    {
                        hasVendors !== true || isAdmin === false ? (
                            null
                        ) : (
                            <Tooltip placement="right" mouseEnterDelay={TOOLTIP_POP_DELAY} title="Edit next evaluation date">
                                <ChangeDateOfServiceForm
                                    setShouldUpdate={setShouldUpdate}
                                    objectId={getCurrentVendorData(OBJECT_ID, currentVendorIndex)}
                                    openModalIcon={<EditOutlined />}
                                />
                            </Tooltip>
                        )
                    }
                </div>
            </div>
        );
    }

    const siderProps = {
        siderTitle: "Our Vendors",
        menuItems: siderMenuItems,
        setCurrentSelection: setCurrentVendorIndex,
        hasAddMenuItemButton: isAdmin,
        addMenuItemProps: {
            style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: "vertical",
                backgroundColor: "white",
            },
            buttonComponent: <AddVendorForm setShouldUpdate={setShouldUpdate} />
        }
    };

    const getCurrentVendorData = (dataName, index) => {
        if (Array.isArray(data) && data.length !== 0) {
            switch (dataName) {
                case VENDOR_NAME:
                    return data[index].vendorName;
                case DATE_OF_SERVICE:
                    return data[index].dateOfService;
                case VENDOR_EVALUATION_HISTORY:
                    return data[index].vendorEvaluationHistory;
                case LAST_VENDOR_EVALUATION_DATE:
                    if (!data[index].vendorEvaluationHistory[0]) return "--"
                    else return dateConverter(data[index].vendorEvaluationHistory[0].vendorEvaluationDate);
                case LAST_VENDOR_EVALUATION:
                    if (!data[index].vendorEvaluationHistory[0]) return {}
                    else return data[index].vendorEvaluationHistory[0].vendorEvaluationReport;
                case NEXT_VENDOR_EVALUATION_DATE:
                    if (!data[index].nextVendorEvaluationDate || data[index].nextVendorEvaluationDate === "") return "--"
                    else return dateConverter(data[index].nextVendorEvaluationDate);
                case OBJECT_ID:
                    return data[index].objectId;
                case TYPENAME:
                    return data[index].typeName;
                case VENDOR_PRODUCTS:
                    return data[index].vendorProducts;
                default:
                    break;
            }
        }
    }

    const vendorDescriptionProps = {
        currentVendor: {
            title: getCurrentVendorData(VENDOR_NAME, currentVendorIndex),
            dateOfService: renderDateOfService(),
            lastVendorEvaluationReport:
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {
                        getCurrentVendorData(LAST_VENDOR_EVALUATION_DATE, currentVendorIndex) === "--"
                            ?
                                <p
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    --
                                </p>
                            :
                                <div
                                    style={{
                                        position: "relative",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <ReportLink
                                        id={getCurrentVendorData(OBJECT_ID, currentVendorIndex)}
                                        type={VENDOR_EVALUATION_REPORT + "-0"}
                                        text={getCurrentVendorData("LAST_VENDOR_EVALUATION_DATE", currentVendorIndex)}
                                        keyid={getCurrentVendorData(OBJECT_ID, currentVendorIndex)}
                                    />
                                </div>
                    }
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                    {
                        getCurrentVendorData(LAST_VENDOR_EVALUATION_DATE, currentVendorIndex) === "--" || hasVendors !== true ? (
                            null
                        ) : (
                            <EvaluationHistoryModal
                                vendorEvaluationHistory={getCurrentVendorData(VENDOR_EVALUATION_HISTORY, currentVendorIndex)}
                                vendorId={getCurrentVendorData(OBJECT_ID, currentVendorIndex)}
                                isVendorHistory={true}
                            />
                        )
                    }
                    {
                        hasVendors !== true || isAdmin !== true ? (
                            null
                        ) : (
                            <div style={{ marginLeft: "5px" }}>
                                <AddVendorEvaluationForm
                                    vendorEvaluationHistory={getCurrentVendorData(VENDOR_EVALUATION_HISTORY, currentVendorIndex)}
                                    setShouldUpdate={setShouldUpdate}
                                    objectId={getCurrentVendorData(OBJECT_ID, currentVendorIndex)}
                                />
                            </div>
                        )
                    }
                    </div>
                </div>,
            nextEvaluationDate: renderNextEvaluationDescription()
        },
    }

    const vendorProductDataSource = () => {
        if (Array.isArray(data) && data[currentVendorIndex]) {
            if (Array.isArray(data[currentVendorIndex].vendorProducts) && data[currentVendorIndex].vendorProducts.length > 0) {
                return data[currentVendorIndex].vendorProducts.map((product, idx) => {
                    let lastEvaluationDate, nextEvaluationDate;
                    if (!product?.productEvaluationHistory[0]) { lastEvaluationDate = "--"; }
                    else {
                        lastEvaluationDate = product.productEvaluationHistory[0].productEvaluationDate;
                        lastEvaluationDate = dateConverter(lastEvaluationDate);
                    }
                    if (!product?.nextProductEvaluationDate) { nextEvaluationDate = "--"; }
                    else {
                        nextEvaluationDate = product.nextProductEvaluationDate;
                        nextEvaluationDate = dateConverter(nextEvaluationDate);
                     }
                    return {
                        key: `product-row-${idx}`,
                        productName: product.productName,
                        lastEvaluationDate: lastEvaluationDate,
                        nextEvaluationDate: nextEvaluationDate,
                    }
                })
            }
        }
    }

    const vendorProductTableProps = {
        columns: [
            {
                title: "Product Name",
                dataIndex: "productName",
                key: 'productName',
            },
            {
                title: "Last Evaluation Date",
                dataIndex: "lastEvaluationDate",
                key: "lastEvaluationDate",
                render: (text, tableData, index) =>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-start",
                            }}
                        >
                            <ReportLink
                                id={getCurrentVendorData(OBJECT_ID, currentVendorIndex)}
                                type={`${PRODUCT_EVALUATION_REPORT}-${index}-0`}
                                text={text}
                                keyid={getCurrentVendorData(OBJECT_ID, currentVendorIndex)}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent:"flex-end",
                                alignItems: "center",
                            }}
                        >
                            <EvaluationHistoryModal
                                vendorId={getCurrentVendorData(OBJECT_ID, currentVendorIndex)}
                                isVendorHistory={false}
                                vendorProducts={getCurrentVendorData(VENDOR_PRODUCTS, currentVendorIndex)}
                                productIndex={index}
                            />
                            {
                                !isAdmin ? (
                                    null
                                ) : (
                                    <AddProductEvaluationForm
                                        vendorProducts={getCurrentVendorData(VENDOR_PRODUCTS, currentVendorIndex)}
                                        productIndex={index}
                                        setShouldUpdate={setShouldUpdate}
                                    />
                                )
                            }
                        </div>
                    </div>
            },
            {
                title: "Next Evaluation Date",
                dataIndex: "nextEvaluationDate",
                key: "nextEvaluationDate",
                render: (text, tableData, productIndex) => {
                    return (
                        <>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                    }}
                                >
                                    <Typography>{text}</Typography>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent:"flex-end",
                                    }}
                                >
                                    {
                                        !isAdmin ? (
                                            null
                                        ) : (
                                            <ChangeNextEvaluationDateForm
                                                openModalIcon={<EditOutlined />}
                                                evaluationTarget="PRODUCT"
                                                objectId={getCurrentVendorData(OBJECT_ID, currentVendorIndex)}
                                                vendorProducts={getCurrentVendorData(VENDOR_PRODUCTS, currentVendorIndex)}
                                                productIndex={productIndex}
                                                setShouldUpdate={setShouldUpdate}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </>
                    );
                }
            },
        ],
        dataSource: vendorProductDataSource(),
        hasAddProductButton: isAdmin,
        addProductButton:
            <>
                {
                    hasVendors !== true ? (
                        null
                    ) : (
                        <AddProductForm
                            vendorProducts={getCurrentVendorData(VENDOR_PRODUCTS, currentVendorIndex)}
                            setShouldUpdate={setShouldUpdate}
                            vendorId={getCurrentVendorData(OBJECT_ID, currentVendorIndex)}
                        />
                    )
                }
            </>,
        hasAddProductEvaluationButton: isAdmin,
        loading,
    }

    return (
        <Row align="top">
            <Col span={4}>
                <TabSider {...siderProps} />
            </Col>
            <Col span={20}>
                    <Skeleton loading={loading} active>
                        <VendorDescription {...vendorDescriptionProps} />
                    </Skeleton>
                    <Divider />
                    <Typography.Title level={3}>Products</Typography.Title>
                    <VendorProductTable {...vendorProductTableProps} />
            </Col>
        </Row>
    );
}

VendorTab.propTypes = {
    isAdmin: PropTypes.bool,
}

export default VendorTab;