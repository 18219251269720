import { Auth } from 'aws-amplify';
import axios from 'axios';
import { message } from 'antd';
import { TRUST_CENTER_GROUPS_API } from '../static/endpoints';

/**
 * Makes an API call to post information regarding a user's desire to subscribe or unsubscribe to release notes updates.
 * @async
 * @function postSubscription
 * 
 * @param {Object} productInfo Object that contains the information about the products concerned with the subscription
 * @param {Object} user Object that contains the current user's information
 * @returns {Promise} Promise represents the response to the postSubscription request
 */
export const postSubscription = async (productInfo, user) => {
    const key = 'updatable';
    message.loading({
        content: "loading...",
        top: 100,
        duration: 0,
        key
    });

    const formData = new FormData();
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();

    formData.append('username', user.username);
    formData.append('subscriptionInfo', JSON.stringify(productInfo, undefined, 2));

    try {
        const response = await axios.post(TRUST_CENTER_GROUPS_API,
            formData,
            {
                headers: {
                    "Authorization": token
                }
            }
        );
        message.success({
            content: "Success!",
            top: 100,
            duration: 1.5,
            key
        });
        return response;
    } catch (err) {
        console.error("error posting data:", err, err.response);
        if (err.request.status && err.request.status === 404) {
            message.success({
                content: "Error!",
                top: 100,
                duration: 1.5,
                key 
            })
        } else {
            message.error({
                content: "Error!",
                top: 100,
                duration: 1.5,
                key
            })
        }
    }
}