import { useState } from "react";
import { useData } from "../../lib/useData";
import ReportLink from "../navigation/ReportLink";
import ArtifactsTable from "../tables/ArtifactsTable";
import { FilterFilled } from "@ant-design/icons";
import { Tooltip, Typography } from 'antd';
import { columnCreator } from "../../utils/column-creator";
import ArtifactDocumentForm from '../forms/ArtifactDocumentForm';
import { dateConverter } from "../../utils/dateConverter";
import PropTypes from 'prop-types';
import constants from '../../static/constants.json';
import ArtifactAddNewVersionForm from "../forms/ArtifactAddNewVersionForm";
import { useAuthenticator } from "@aws-amplify/ui-react";

const ArtifactsTab = ({ isAdmin }) => {
    const [shouldUpdate, setShouldUpdate] = useState(true);
    const { route: authRoute } = useAuthenticator(context => [context.route])
    const objectType = constants.OBJECT_TYPES.ARTIFACT;
    const reportType = constants.REPORT_TYPES.ARTIFACT_DOCUMENT;
    let columns;

    const [loading, dataSource] = useData(objectType, shouldUpdate, setShouldUpdate);

    let columnProps = {
        titles: ["Name", "Department", "Effective Date", "Version"],
        dataIndexes: ["name", "department", "effectiveDate", "version"],
        keys: ["name", "department", "effectiveDate", "version"],
        widths: `${100 / NUMBER_OF_COLUMNS}%`,
    }

    let filters = DEPARTMENTS.map((department) => {return {text: department.title, value: department.title}});


    const nameRender = (name, tableData) => {
        console.log({name, tableData});
        if (!tableData.artifactDocument) {
            return <p>N/A</p>
        } else {
            return <ReportLink
                    id={tableData.objectId}
                    type={reportType}
                    text={name}
                    keyid={tableData.objectId}
                />
        }
    }

    const filterIcon = () =>  (<Tooltip placement="topLeft" title="Filter Product"><FilterFilled style={{ fontSize: "15px" }} /></Tooltip>);
    const onFilter = (documentType, record) => record.documentType.indexOf(documentType) === 0;

    columns = columnCreator(columnProps);

    columns[NAME].render = (name, tableData) => nameRender(name, tableData);

    columns[DEPARTMENT].filterIcon = filterIcon();
    columns[DEPARTMENT].filters = filters;
    columns[DEPARTMENT].onFilter = (department, record) => onFilter(department, record);

    columns[EFFECTIVE_DATE].render = (effectiveDate) => dateConverter(effectiveDate);

    columns[VERSION].render = (version, tableData) => {
        console.log({TableData: tableData, Version: version});
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <Typography
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {version}
                </Typography>
                {
                    authRoute === "authenticated" && isAdmin ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                            }}
                        >
                            {
                                tableData.children || tableData.children === null ? (
                                    <ArtifactAddNewVersionForm setShouldUpdate={setShouldUpdate} tableData={tableData} />
                                ) : (
                                    <div style={{ width: "32px" }} />
                                )
                            }
                        </div>
                    ) : (
                        null
                    )
                }
            </div>
        )
    }

    const expandable = {
        indentSize: 20,
    }

    const footer = () => {
        return (
            <div>
                {
                    authRoute === "authenticated" && isAdmin
                    ? (
                        <ArtifactDocumentForm
                            setShouldUpdate={setShouldUpdate}
                            names={() => {
                                var names = dataSource.map((row, idx) => {
                                    return {
                                        key: row.name + idx,
                                        label: row.name,
                                        value: row.name,
                                        department: row.department
                                    }
                                })
                                return names;
                            }}
                        />
                    ) : null
                }
            </div>
        );
    }
    const artifactTableProps = {
        columns: columns,
        dataSource: dataSource,
        expandable: expandable,
        loading,
        footer: footer,
    }
    return (
        <ArtifactsTable
            artifactTableProps={artifactTableProps}
        />
    );
}

ArtifactsTab.propTypes = {
    isAdmin: PropTypes.bool,
}

export default ArtifactsTab;

const DEPARTMENTS = [
    {title: "Product Development"},
    {title: "Validation"},
    {title: "Information Technology"},
    {title: "Finance"},
    {title: "Human Resources"},
    {title: "Study Implementation"},
    {title: "Custom Support"},
    {title: "Product Management"},
    {title: "Client Services"},
    {title: "Legal"},
    {title: "Sales"},
    {title: "Marketing"},
    {title: "Quality"},
    {title: "Management"}
];

const
    NAME = 0,
    DEPARTMENT = 1,
    EFFECTIVE_DATE = 2,
    VERSION = 3,
    NUMBER_OF_COLUMNS = 4;