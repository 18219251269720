import React, { useState } from 'react';
import DragAndDrop from '../fields/DragAndDrop';
import {
    Input,
    Select,
    DatePicker,
    Form,
    AutoComplete
} from 'antd';
import { useSubmitForm } from '../../lib/useSubmitForm';
import FormModal from '../modals/FormModal';
import PropTypes from 'prop-types';
import constants from '../../static/constants.json';

const { ARTIFACT } = constants.OBJECT_TYPES;

const { Option } = Select;

const departmentOptions = [
    'Product Development',
    'Validation',
    'Information Technology',
    'Finance',
    'Human Resources',
    'Study Implementation',
    'Custom Support',
    'Product Management',
    'Client Services',
    'Legal',
    'Sales',
    'Marketing',
    'Quality',
    'Management'
];

const ArtifactDocumentForm = ({ setShouldUpdate, names }) => {
    const [ form ] = Form.useForm();
    const [shouldDisable, setShouldDisable] = useState(false);
    const [autoCompleteNames, setAutoCompleteNames] = useState([]);
    const objectType = ARTIFACT;
    let hookParams = {
        form,
        objectType,
        setShouldUpdate,
    }

    const [setShouldSubmitForm] = useSubmitForm(hookParams);

    const onOpen = () => {
        setAutoCompleteNames(names())
    };

    const onSubmit = () => {
        setShouldSubmitForm(() => true);
        setShouldDisable(false);
    };

    const onClear = () => {
        form.resetFields();
        setShouldDisable(false);
    };

    const renderOptions = (options) => {
        return (
            options.map((option, idx) => {
                return <Option key={option + idx} value={ option }>{ option }</Option>
            })
        );
    }

    const onNameChange = () => {
        let name = form.getFieldValue('name');
        let nameTypePairs = names().map((name) => [name.value, name.department]);
        let department;

        for (let i = 0; i < nameTypePairs.length; i++) {
            var pair = nameTypePairs[i]
            if (pair[0] !== name) {
                setShouldDisable(false)
            } else {
                department = pair[1];
                form.setFieldsValue({
                    department: department
                });
                setShouldDisable(true)
                break;
            }
        }
    }

    const openButton = {
        text: "Add Artifact Document",
    }

    const modalForm = {
        title: "Artifact Form",
        onSubmit,
        onClear,
        onOpen,
    }

    return (
        <>
            <FormModal openButton={openButton} modalForm={modalForm}>
                <Form 
                    form={ form }
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                            {
                                required: true,
                                message: "Name required"
                            },
                        ]}
                    >
                        <AutoComplete
                            options={autoCompleteNames}
                            placeholder="Please input document name"
                            filterOption={true}
                            onChange={onNameChange}
                        />
                    </Form.Item>
                    <Form.Item
                        name="department"
                        label="Department"
                        rules={[
                            {
                                required: true,
                                message: "Department required"
                            },
                        ]}
                        shouldUpdate
                    >
                        <Select
                            style={{
                                padding: "5px 0"
                            }}
                            disabled={shouldDisable}
                        >
                            { renderOptions(departmentOptions) }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="effectiveDate"
                        label="Effective Date"
                        rules={[
                            {
                                required: true,
                                message: "Effective date required"
                            },
                        ]}
                    >
                        <DatePicker />
                    </Form.Item>
                    <Form.Item
                        name="version"
                        label="Version"
                        rules={[
                            {
                                required: true,
                                message: "Version required"
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="artifactDocument"
                        label="Artifact Document"
                        rules={[
                            {
                                required: true,
                                message: "Artifact document PDF required"
                            },
                        ]}
                    >
                        <DragAndDrop
                            accept="application/pdf"
                            beforeUpload={() => false}
                        />
                    </Form.Item>
                </Form>
            </FormModal>
        </>
    );
}

export default ArtifactDocumentForm;

ArtifactDocumentForm.propTypes = {
    setShouldUpdate: PropTypes.func,
    names: PropTypes.func,
}