import IdleTimer from 'react-idle-timer';
import React, { useRef } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { timeoutPath } from '../../static/routes';
// import { AuthState } from '@aws-amplify/ui-components';
import PropTypes from 'prop-types';
import { useAuthenticator } from '@aws-amplify/ui-react';

const MILLISECONDS_PER_SECOND = 1000;
const SECONDS_PER_MINUTE = 60;
const minutesToTimeout = 15;
//const testTimeout = 5000;

const SignOutIdleTimer = ({ signOut }) => {
    const idleTimerRef = useRef(null);
    const history = useHistory();
    const { route: authRoute } = useAuthenticator(context => [context.route])


    const onIdle = () => {
        if (authRoute === "authenticated") {
            signOut()
            console.info("User idle, logging out.");
            history.push(generatePath("/error/:errorCode", {
                errorCode: timeoutPath.slice(1),
            }));
        }
    }
    return (
        <div>
            <IdleTimer
                ref={ idleTimerRef }
                timeout={ MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE * minutesToTimeout }
                //timeout={ testTimeout }
                onIdle={ onIdle }
            >
            </IdleTimer>
        </div>
    )
}

SignOutIdleTimer.propTypes = {
    authRoute: PropTypes.string,
}

export default SignOutIdleTimer;
