import { Dropdown, Menu } from 'antd'
import React from 'react';
import { addUserToGroup } from '../../utils/admin-utils';
import constants from '../../static/constants.json';

const { ACCESS_GROUPS } = constants.GROUPS;

const DropdownMenu = (props) => {
    const { accessLevels, itemKey, username, setShouldFetch } = props;

    const onMenuItemClick = (menuItem) => {
        let groupname = menuItem.key.split("-")[0];

        addUserToGroup(username, groupname)
            .then(() => {
                console.log({AddUserToGroup: "SUCCESS"});
                setShouldFetch(() => true);
            })
            .catch((err) => {
                console.error({AddUserToGroup: "FAILED", Message: err});
            })
        ;
    }

    return (
        <>
            <Dropdown
                overlay={() => (
                    <Menu onClick={onMenuItemClick}>
                        {
                            ACCESS_GROUPS.map((group) => {
                                if (accessLevels.includes(group) !== true) {
                                    return (
                                        <Menu.Item key={`${group}-${itemKey}`}>
                                            { group }
                                        </Menu.Item>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        }
                    </Menu>
                )}
                trigger={['click']}
            >
                { props.children }
            </Dropdown>
        </>
    );
}

export default DropdownMenu;