import React from 'react';
import {
    Redirect,
    Route
} from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { rootPath } from '../../static/routes';
import PropTypes from 'prop-types';

const ProtectedRoute = (props) => {
    const { route } = props;
    const { route: authRoute } = useAuthenticator(context => [context.route])

    return (
        <>
        {
            authRoute === "signIn" || authRoute === "resetPassword"
            ? <Redirect to={rootPath} />
            : (
                <Route { ...props }>
                    { route }
                </Route>
            )
        }
        </>
    );
}

ProtectedRoute.propTypes = {
    route: PropTypes.node,
}

export default ProtectedRoute;