import { FilterFilled } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useData } from '../../lib/useData';
import { products } from '../../static/products';
import { columnCreator } from '../../utils/column-creator';
import { dateConverter } from '../../utils/dateConverter';
import ProductReleaseForm from '../forms/ProductReleaseForm';
import ReportLink from '../navigation/ReportLink';
import ProductReleaseTable from '../tables/ProductReleaseTable';
import ReportDownloader from '../download/ReportDownloader';
import constants from '../../static/constants.json';
import PopoverMenu from '../menus/PopoverMenu';
import sponsors from '../../static/sponsors.json';
import { useAuthenticator } from '@aws-amplify/ui-react-core';

const {
    SIGNED_RELEASE, TESTING_EVIDENCE,
    INSTALLATION_QUALIFICATION,
} = constants.REPORT_TYPES;

const ProductReleaseTab = ({isAdmin}) => {
    const [shouldUpdate, setShouldUpdate] = useState(true);
    const objectType = constants.OBJECT_TYPES.PRODUCT_RELEASE;

    const { route: authRoute } = useAuthenticator(context => [context.route])
    let columns;

    let [loading, dataSource] = useData(objectType, shouldUpdate, setShouldUpdate);

    let columnProps = {
        titles: ["Product", "Release Date", "Release Number", "Signed Release Report", "Testing Evidence", "Installation Qualification", "Artifact Archive"],
        dataIndexes: ["product", "releaseDate", "releaseNumber", "signedRelease", "testingReports", "installationQualification", "zipDownloads"],
        keys: ["product", "releaseDate", "releaseNumber", "signedRelease", "testingReports", "installationQualification", "zipDownloads"],
        widths: `${100 / NUMBER_OF_COLUMNS}`,
    }

    let filters = [
        { text: products.flatironVessel.title, value: products.flatironVessel.title },
        { text: products.flatironClinicalPipe.title, value: products.flatironClinicalPipe.title },
        { text: products.flatironSourceUpload.title, value: products.flatironSourceUpload.title },
        { text: "Protocol First", value: "Protocol First" },
        { text: "Clinical Pipe", value: "Clinical Pipe" },
        { text: "Source Upload", value: "Source Upload" }
    ];

    let dateSortDirections = ['descend'];
    let dateDefaultSortOrder = 'descend';

    const renderFilterIcon = () => (
        <Tooltip
            placement="topLeft"
            title="Filter Product"
        >
            <FilterFilled style={{ fontSize: "15px" }} />
        </Tooltip>
    );
    const onFilter = (product, record) => record.product.indexOf(product) === 0

    const sorter = (a, b) => moment(a.releaseDate).unix() - moment(b.releaseDate).unix()
    const renderDate = (date) => dateConverter(date);

    const renderSignedRelease = (signedRelease, tableData) => {
        const objectId = tableData.objectId;
        let viewText = "View";

        if (!signedRelease) viewText = "N/A"

        return renderArtifact(objectId, SIGNED_RELEASE, viewText);
    }

    const getSponsorName = (sponsorCode) => {
        if (!sponsors[sponsorCode]) {
            return sponsorCode;
        } else {
            return sponsors[sponsorCode].name;
        }
    }

    const renderTestingEvidence = (testingReports, tableData) => {
        const objectId = tableData.objectId;
        let viewText = "View";

        if (!testingReports || (Array.isArray(testingReports) && testingReports.length <= 1)) {
            if (!testingReports || testingReports.length === 0) {
                viewText = "N/A";
                return renderArtifact(objectId, TESTING_EVIDENCE, viewText)
            } else if (testingReports.length === 1) {
                return renderArtifact(testingReports[0].s3Key.split("-")[1], TESTING_EVIDENCE, viewText);
            }
        } else {
            let testingReportList = testingReports.map(testingReport => {
                let testReportId;
                let linkText;
                if (testingReport.sponsorCode === "CORE") {
                    linkText = "Main";
                    testReportId = testingReport.s3Key.split("-")[1];
                } else {
                    linkText = getSponsorName(testingReport.sponsorCode);
                    testReportId = testingReport?.parentId?.split("-")[1] + "_" + testingReport.sponsorCode
                }

                let reportLinkProps = {
                    id: testReportId,
                    type: TESTING_EVIDENCE,
                    text: linkText,
                    keyid: testReportId,
                }

                return <ReportLink { ...reportLinkProps } />
            })

            let popoverMenuProps = {
                listOptions: testingReportList,
                popoverPlacement: 'rightTop',
                menuHeader: 'Options',
                menuLabel: viewText,
            }

            return (
                <PopoverMenu { ...popoverMenuProps } />
            )
        }

    }

    const renderInstallationQualification = (installationQualification, tableData) => {
        const objectId = tableData.objectId;
        let viewText = "View";

        if (!installationQualification) viewText = "N/A"

        return renderArtifact(objectId, INSTALLATION_QUALIFICATION, viewText);
    }

    const renderZipDownload = (zipDownloads, tableData) => {
        let viewText = "Download";
        let downloadParams = {
            objectId: tableData.objectId,
            objectType: tableData.objectType,
            product: tableData.product,
            releaseNumber: tableData.releaseNumber,
        };

        if (!zipDownloads || (Array.isArray(zipDownloads) && zipDownloads.length <= 1)) {
            console.log({zipDownloads})
            if (!zipDownloads || zipDownloads.length === 0) {
                return <Typography>N/A</Typography>
            } else if (zipDownloads.length === 1) {
                return (
                    <ReportDownloader
                        downloadParams={{
                            ...downloadParams,
                            ...zipDownloads[0]
                        }}
                        hasOneReport={true}
                        viewText={viewText}
                        key={downloadParams.objectId}
                    />
                )
            }
        } else {
            const zipDownloadList = zipDownloads.map(zipDownload => {
                downloadParams = {
                    ...downloadParams,
                    ...zipDownload,
                }
                downloadParams.sponsorName = getSponsorName(downloadParams.sponsorCode);

                const reportDownloaderProps = {
                    downloadParams,
                    viewText,
                    hasOneReport: false,
                    key: downloadParams.objectId,
                }

                return <ReportDownloader { ...reportDownloaderProps } />
            });

            const popoverMenuProps = {
                listOptions: zipDownloadList,
                popoverPlacement: "rightTop",
                menuHeader: "Options",
                menuLabel: viewText,
            }

            return <PopoverMenu { ...popoverMenuProps } />
        }
    }

    const renderArtifact = (id, type, text) => {
        if (text !== "N/A") {
            return (
                <ReportLink
                    id={id}
                    type={type}
                    text={text}
                />
            );
        } else { return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                N/A
            </div>
        )}
    }

    columns = columnCreator(columnProps);

    columns[PRODUCT].filterIcon = renderFilterIcon();
    columns[PRODUCT].filters = filters;
    columns[PRODUCT].onFilter = (product, record) => onFilter(product, record);

    columns[RELEASE_DATE].sorter = (a, b) => sorter(a, b);
    columns[RELEASE_DATE].sortDirections = dateSortDirections;
    columns[RELEASE_DATE].defaultSortOrder = dateDefaultSortOrder;
    columns[RELEASE_DATE].render = (date) => renderDate(date);

    columns[SIGNED_RELEASE_INDEX].render = (signedRelease, tableData) => renderSignedRelease(signedRelease, tableData);

    columns[TESTING_EVIDENCE_INDEX].render = (testingReports, tableData) => renderTestingEvidence(testingReports, tableData);

    columns[INSTALLATION_QUALIFICATION_INDEX].render = (installationQualification, tableData) => renderInstallationQualification(installationQualification, tableData);

    columns[ZIP_DOWNLOAD_INDEX].render = (zipDownloads, tableData) => renderZipDownload(zipDownloads, tableData);

    const footer = () => (
        <>
            <div>
            {
                authRoute === "authenticated" && isAdmin ? (
                    <ProductReleaseForm setShouldUpdate={setShouldUpdate} />
                ) : null
            }
            </div>
        </>
    )

    const productReleaseTableProps = {
        columns: columns,
        dataSource: dataSource,
        footer: footer,
        loading: {
            spinning: loading,
            size: "small",
        },
        tableLayout: "auto",
    };

    return (
        <div>
            <ProductReleaseTable
                productReleaseTableProps={productReleaseTableProps}
            />
        </div>
    );
}

ProductReleaseTab.propTypes = {
    isAdmin: PropTypes.bool,
}

export default ProductReleaseTab;

const
    PRODUCT = 0,
    RELEASE_DATE = 1,
    // RELEASE_NUMBER = 2,
    SIGNED_RELEASE_INDEX = 3,
    TESTING_EVIDENCE_INDEX = 4,
    INSTALLATION_QUALIFICATION_INDEX = 5,
    ZIP_DOWNLOAD_INDEX = 6,
    NUMBER_OF_COLUMNS = 7;
