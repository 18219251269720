import axios from 'axios';
import constants from '../static/constants.json';
import { TRUST_CENTER_USER_API } from '../static/endpoints';

const { USER } = constants.OBJECT_TYPES;

export const fetchUserAccess = async (user, token) => {
    let uid;
    if (user.UserAttributes) {
        uid = user.UserAttributes[0].Value;
    } else if (user.Attributes) {
        uid = user.Attributes[0].Value;
    } else {
        throw Error("No uid found")
    }
    const username = user.Username;

    const params = {
        headers: {
            Authorization: token,
        },
        params: {
            objectType: USER,
            username
        }
    }
    try {
        return await axios.get(`${TRUST_CENTER_USER_API}/${uid}`, params);
    } catch (error) {
        console.error({FetchUserAccessError: error});
    }
}

export const updateUserAccess = async (user, accessList, accessType, token) => {
    console.log({user});
    console.log({token});

    let uid;
    if (user.UserAttributes) {
        uid = user.UserAttributes[0].Value;
    } else if (user.Attributes) {
        uid = user.Attributes[0].Value;
    } else {
        throw Error("No uid found")
    }

    const formData = new FormData();
    formData.append("uid", uid);
    formData.append("accessListJSON", JSON.stringify(accessList, undefined, 2));
    formData.append("objectType", "updateUser");
    formData.append("accessType", accessType);

    try {
        const fetchResponse = await fetchUserAccess(user, token);
        if (fetchResponse.status < 400) {
            const response = await axios({
                method: 'put',
                url: `${TRUST_CENTER_USER_API}/${uid}/update`,
                headers: {
                    "Authorization": token,
                },
                data: formData,
            });
            console.log({UpdateUserAccessResponse: response});
        }
    } catch (error) {
        console.error({UpdateUserAccessError: error})
    }
}