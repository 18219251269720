import React, { useState } from 'react';
import {
    Button,
    Modal,
    Card,
    Tooltip,
} from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { dateConverter } from '../../utils/dateConverter';
import PropTypes from 'prop-types';

const gridStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start'
};

const EvaluationHistoryModal = (props) => {
    const {
        vendorId,
        vendorEvaluationHistory,
        isVendorHistory,
        vendorProducts,
        productIndex
    } = props;
    const [isVisible, setIsVisible] = useState(false);

    const renderVendorCardWithTooltip = (vendorEvaluation, idx) => (
        <Tooltip
            key={`vendor-evaluation-grid-tooltip-${idx}`}
            title={`View ${vendorEvaluation.vendorEvaluationType} for ${dateConverter(vendorEvaluation.vendorEvaluationDate)}`}
            placement="right"
        >
            <Card.Grid
                key={`vendor-evaluation-grid-options-${idx}`}
                style={gridStyle}
                onClick={() => {
                    window.open(`${window.location.origin}/report/${vendorId}/vendorEvaluationReport-${idx}`, "_blank");
                }}
            >
                {vendorEvaluation.vendorEvaluationType}
                <div
                    key={`vendor-evaluation-date-${idx}`}
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    {dateConverter(vendorEvaluation.vendorEvaluationDate)}
                </div>
            </Card.Grid>
        </Tooltip>
    );

    const renderProductCardWithTooltip = (productEvaluation, idx) => (
        <Tooltip
            key={`product-evaluation-grid-tooltip-${idx}`}
            title={`View ${productEvaluation.productEvaluationType} for ${dateConverter(productEvaluation.productEvaluationDate)}`}
            placement="right"
        >
            <Card.Grid
                key={`product-evaluation-grid-options-${idx}`}
                style={gridStyle}
                onClick={() => {
                    window.open(
                        `${window.location.origin}/report/${vendorId}/productEvaluationReport-${productIndex}-${idx}`,
                        "_blank"
                    );
                }}
            >
                {productEvaluation.productEvaluationType}
                <div
                    key={`product-evaluation-date-${idx}`}
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    {dateConverter(productEvaluation.productEvaluationDate)}
                </div>
            </Card.Grid>
        </Tooltip>
    )

    return (
        <>
            <Tooltip title="View Evaluation History">
                <Button type="text" shape="circle" size="small" icon={<HistoryOutlined />} onClick={() => setIsVisible(true)} />
            </Tooltip>
            <Modal
                title="Evaluation History"
                open={isVisible}
                cancelButtonProps={{
                    style: {
                        visibility: 'hidden',
                    }
                }}
                okText="Done"
                onOk={() => setIsVisible(false)}
                closable={false}
            >
                {
                    isVendorHistory === true ? (
                        <Card>
                            {
                                Array.isArray(vendorEvaluationHistory) ? (
                                    vendorEvaluationHistory.map((vendorEvaluation, idx) => {
                                        return renderVendorCardWithTooltip(vendorEvaluation, idx);
                                    })
                                ) : (
                                    null
                                )
                            }
                        </Card>
                    ) : (
                        <Card>
                            {
                                Array.isArray(vendorProducts[productIndex].productEvaluationHistory) ? (
                                    vendorProducts[productIndex].productEvaluationHistory.map((productEvaluation, idx) => {
                                        return renderProductCardWithTooltip(productEvaluation, idx);
                                    })
                                ) : (
                                    null
                                )
                            }
                        </Card>
                    )
                }
            </Modal>
        </>
    );
}

EvaluationHistoryModal.propTypes = {
    vendorId: PropTypes.string,
    vendorEvaluationHistory: PropTypes.array,
    isVendorHistory: PropTypes.bool,
    vendorProducts: PropTypes.array,
    productIndex: PropTypes.number,
}

export default EvaluationHistoryModal;
