import moment from 'moment';

/**
 * Converts a date string (usually in ISO format) into a format that is normal for the user's locale
 * @function dateConverter
 *
 * @param {string} date The date string that is being converted
 * @returns {string} A date string that reflects the format of the user's locale
 */
export const dateConverter = (date) => {
    if (!date) { return; }
    const localeLanguageString = navigator.language;
    if (date.includes("/")) {
        date = moment(date, "MM/DD/YYYY");
    } else { date = moment(date) }

    var localeDateString = new Date(date).toLocaleDateString(localeLanguageString);
    let dateSplit = localeDateString.split(/\D/i);
    let symbols = localeDateString.split(/\d+/i);
    let delimiters = symbols.toString().replaceAll(",", "");
    let index = 0;
    while (localeDateString.length < 10 && localeDateString.match(/\d+\D\d+\D\d+/i)) {
        if (dateSplit[index].length === 1) {
            dateSplit[index] = `0${dateSplit[index]}`;
            localeDateString = `${dateSplit[0]}${delimiters[0]}${dateSplit[1]}${delimiters[1]}${dateSplit[2]}`;
        }
        index++;
    }
    return localeDateString;
}