import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

const ReleaseNotesTable = ({ releaseNotesTableProps }) => {
    return (
        <>
            <div>
                <Table
                    { ...releaseNotesTableProps }
                />
            </div>
        </>

    )
}

ReleaseNotesTable.propTypes = {
    releaseNotesTableProps: PropTypes.shape({
        columns: PropTypes.array.isRequired,
        dataSource: PropTypes.array,
        expandable: PropTypes.object,
        loading: PropTypes.bool,
        footer: PropTypes.func,
    })
}

export default ReleaseNotesTable;