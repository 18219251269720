import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);
console.log({endpoints: Amplify.configure().aws_cloud_logic_custom})

let port = process.env.REACT_APP_PORT;
console.log({port})
if (Amplify.configure().aws_cloud_logic_custom[0].endpoint.toString().endsWith("/dev") === true) {
    port = "8443";
}

export const TRUST_CENTER_REPORT_API = port === "8443" ? `https://develop.trustcenterapi.protocolfirst.com:${port}/api/report` : `${process.env.REACT_APP_API_BASE_URL}:${port}/api/report`;
export const TRUST_CENTER_GROUPS_API = port === "8443" ? `https://develop.trustcenterapi.protocolfirst.com:${port}/api/groups` : `${process.env.REACT_APP_API_BASE_URL}:${port}/api/groups`;
export const TRUST_CENTER_USER_API = port === "8443" ? `https://develop.trustcenterapi.protocolfirst.com:${port}/api/user` : `${process.env.REACT_APP_API_BASE_URL}:${port}/api/user`;
export const TRUST_CENTER_DOWNLOAD_API = port === "8443" ? `https://develop.trustcenterapi.protocolfirst.com:${port}/api/download` : `${process.env.REACT_APP_API_BASE_URL}:${port}/api/download`;

// export const TRUST_CENTER_API = "https://trustcenterapi.protocolfirst.com:8443/api/report"