import React, { useEffect } from 'react';
import {
    Typography,
    Descriptions,
} from 'antd';
import PropTypes from 'prop-types';

const VendorDescription = (props) => {
    const { currentVendor, } = props;
    useEffect(() => {
        console.log({
            vendorNameChange: currentVendor.title,
            vendorDateOfServiceChange: currentVendor.dateOfService,
        });
    }, [currentVendor.title, currentVendor.dateOfService])

    return (
        <>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <Typography.Title level={1} style={{ margin: '0px 20px 20px 20px' }}>
                    {currentVendor.title}
                </Typography.Title>
            </div>
            <Descriptions bordered column={2} size="default">
                <Descriptions.Item
                    label="Date of Service"
                    labelStyle={{ width: "25%" }}
                    contentStyle={{ width: "25%" }}
                    key="date-of-service"
                >
                    {currentVendor.dateOfService}
                </Descriptions.Item>
                <Descriptions.Item
                    label="Last Vendor Evaluation"
                    labelStyle={{ width: "25%" }}
                    contentStyle={{ width: "25%" }}
                    key="last-vendor-evaluation"
                >
                    {currentVendor.lastVendorEvaluationReport}
                </Descriptions.Item>
                <Descriptions.Item
                    label="Next Evaluation Date"
                    labelStyle={{ width: "25%" }}
                    contentStyle={{ width: "25%" }}
                    key="next-evaluation-date"
                > 
                    {currentVendor.nextEvaluationDate}
                </Descriptions.Item>
                <Descriptions.Item 
                    labelStyle={{ width: "25%" }}
                    contentStyle={{ width: "25%" }}
                    key="place-holder"
                /> 
            </Descriptions>
        </>
    )
}

export default VendorDescription;

VendorDescription.propsTypes = {
    currentVendor: PropTypes.shape({
        title: PropTypes.string,
        dateOfService: PropTypes.string,
        lastVendorEvaluationReport: PropTypes.node,
        nextEvaluationDate: PropTypes.node,
    })
}