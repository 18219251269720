import { Typography, Image, Space } from 'antd';
import { Link } from 'react-router-dom';
import FlatironLogo from '../../static/images/Flatiron Horizontal RGB.png';
import SignOut from '../auth/SignOut';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';

const Header = () => {
    const [ displaySignOut, setDisplaySignOut ] = useState("none");
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (!user) {
            setDisplaySignOut("none");
        } else {
            setDisplaySignOut("flex");
        }
    }, [user]);

    return (
        <div className="app-header">
            <Link to="/">
                <div className="header-title-space">
                    <Image
                        preview={false}
                        src={FlatironLogo}
                        width='120px'
                        height='auto'
                    />
                    <span className="header-title">Trust Center</span>
                </div>
            </Link>
            <Space
                key="header-extras"
                id="header-extras"
                direction="horizontal"
                style={{
                    display: displaySignOut,
                    justifyContent: "space-between"
                }}
            >
                <Typography
                    style={{
                        color: "rgba(0,0,0,.65)",
                        background: "white",
                        fontSize: "14px"
                    }}
                    disabled={true}
                >
                    {!user ? null : user.username}
                </Typography>
                <SignOut />
            </Space>
        </div>
    );
}

export default Header;