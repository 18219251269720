import { message } from 'antd';
import { updateReport as updateReportUtil } from './reportUtils';
/**
 * @callback
 * @function setShouldUpdate
 * @param {boolean} shouldUpdate The boolean that flags the front end to update or not
 */

/**
 * This function sends a PUT API call to update data on the backend
 * @async
 * @function updateReport
 *
 * @param {Object} values The values that are involved in updating the data on the backend
 * @param {string} itemId The ID of the item being updated
 * @param {string} objectType The string code to identify the type of report being updated
 * @param {updateCallback} setShouldUpdate Callback function that tells the front that new data has been put into the backend
 */
export const updateReport = async (values, itemId, objectType, setShouldUpdate) => {
    const key = 'updatable';
    let hasReport = true;
    message.loading({
        content: "loading...",
        top: 100,
        duration: 0,
        key
    });

    const formData = new FormData();

    switch (objectType) {
        case "notes":
            formData.append('objectType', objectType);
            formData.append('productName', values.productName);
            formData.append('releaseNumber', values.releaseNumber);
            formData.append('releaseDate', values.releaseDate.toISOString());
            formData.append('releaseNotes', values.releaseNotes.file);
            break;
        case "vendor":
            console.log({objectType: objectType, evaluationTarget: values.evaluationTarget});
            formData.append('objectType', objectType);
            switch (values.evaluationTarget) {
                case 'VENDOR':
                    formData.append('evaluationTarget', values.evaluationTarget);
                    if (values.isDateOfServiceChange) {
                        formData.append('isDateOfServiceChange', values.isDateOfServiceChange);
                        formData.append('dateOfService', values.dateOfService.toISOString());
                    } else if (values.isNextEvaluationDateChangeOnly) {
                        formData.append('isNextEvaluationDateChangeOnly', values.isNextEvaluationDateChangeOnly)
                        formData.append('nextVendorEvaluationDate', values.nextVendorEvaluationDate.toISOString());
                    } else {
                        formData.append('vendorEvaluationType', values.vendorEvaluationType);
                        formData.append('vendorEvaluationDate', values.vendorEvaluationDate.toISOString());
                        formData.append('vendorEvaluationReport', values.vendorEvaluationReport.file);
                        formData.append('vendorEvaluationHistory', JSON.stringify(values.vendorEvaluationHistory, undefined, 2));
                        if (values.nextVendorEvaluationDate) {
                            formData.append('nextVendorEvaluationDate', values.nextVendorEvaluationDate.toISOString());
                        }
                    }
                    break;
                case 'PRODUCT':
                    formData.append('evaluationTarget', values.evaluationTarget);
                    formData.append('vendorProducts', JSON.stringify(values.vendorProducts, undefined, 2));
                    console.log({values: values});

                    if (values.isNextEvaluationDateChangeOnly !== true) {
                        if (values.isNewProduct === true) {
                            formData.append("productName", values.productName);
                            formData.append("isNewProduct", values.isNewProduct);
                        }

                        if (values.hasProductEvaluation === true) {
                            if (values.isNewProduct === false) {
                                formData.append('isNewProduct', values.isNewProduct);
                                formData.append('productIndex', values.productIndex);
                            }
                            formData.append('productEvaluationType', values.productEvaluationType);
                            formData.append('productEvaluationDate', values.productEvaluationDate.toISOString());
                            formData.append('productEvaluationReport', values.productEvaluationReport.file);
                            formData.append('productEvaluationHistory', JSON.stringify(values.productEvaluationHistory, undefined, 2));
                        }

                    } else {
                        formData.append('isNextEvaluationDateChangeOnly', values.isNextEvaluationDateChangeOnly);
                        // formData.append('vendorProducts', JSON.stringify(values.vendorProducts, undefined, 2));
                        formData.append('productIndex', values.productIndex);
                    }

                    if (values.nextProductEvaluationDate) {
                        formData.append('nextProductEvaluationDate', values.nextProductEvaluationDate.toISOString());
                    }
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }

    try {
        await updateReportUtil(itemId, formData, hasReport);
        message.success({
            content: "Success!",
            top: 100,
            duration: 1.5,
            key
        })
    } catch (err) {
        console.error("error putting data:", err, err.response);
        if (err.request === 404) {
            message.error({
                content: "Error!",
                top: 100,
                duration: 1.5,
                key
            })
        } else {
            message.error({
                content: "Error!",
                top: 100,
                duration: 1.5,
                key
            })
        }
    } finally {
        setShouldUpdate(() => true);
    }
};