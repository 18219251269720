import React from 'react';
import {
    Form,
    DatePicker,
} from 'antd';
import { updateReport } from '../../utils/update-report';
import FormModal from '../modals/FormModal';
import PropTypes from 'prop-types';
import constants from '../../static/constants.json';

const { VENDOR_MANAGEMENT } = constants.OBJECT_TYPES;

const ChangeNextEvaluationDateForm = (props) => {
    const {
        objectId,
        evaluationTarget,
        vendorProducts,
        productIndex,
        setShouldUpdate,
        openModalIcon
    } = props;
    const [form] = Form.useForm();

    const objectType = VENDOR_MANAGEMENT;

    const onOpen = () => {
        console.log({objectId: objectId});
    }

    const onSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                form.resetFields();
                values.isNextEvaluationDateChangeOnly = true;
                values.evaluationTarget = evaluationTarget;
                if (evaluationTarget === "PRODUCT") {
                    values.vendorProducts = vendorProducts;
                    values.productIndex = productIndex;
                }
                updateReport(values, objectId, objectType, setShouldUpdate);
            })
            .catch((err) => {
                console.error({FormValidation: "FAILURE", message: err});
            })
    }

    const onClear = () => {
        form.resetFields();
    }

    const createFormItem = () => {
        if (evaluationTarget === "VENDOR") {
            return (
                <Form.Item
                    name="nextVendorEvaluationDate"
                    rules={[
                        {
                            required: true,
                            message: "Next evaluation date required"
                        }
                    ]}
                >
                    <DatePicker />
                </Form.Item>
            );
        } else if (evaluationTarget === "PRODUCT") {
            return (
                <Form.Item
                    name="nextProductEvaluationDate"
                    rules={[
                        {
                            required: true,
                            message: "Next evaluation date required"
                        }
                    ]}
                >
                    <DatePicker />
                </Form.Item>
            );
        }
    }

    const formItem = createFormItem();

    const modalForm = {
        onSubmit: onSubmit,
        onClear: onClear,
        onOpen: onOpen,
        title: "Next Evaluation Date",
    }

    const openButton = {
        className: "icon-btn",
        type: "text",
        icon: openModalIcon,
        shape: "circle",
        tooltip: {
            title: "Edit Next Evaluation Date",
        }
    }

    return (
        <>
            <FormModal modalForm={modalForm} openButton={openButton}>
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    { formItem }
                </Form>
            </FormModal>
        </>
    );
}

export default ChangeNextEvaluationDateForm;

ChangeNextEvaluationDateForm.propTypes = {
    objectId: PropTypes.string,
    evaluationTarget: PropTypes.string,
    vendorProducts: PropTypes.array,
    productIndex: PropTypes.number, 
    setShouldUpdate: PropTypes.func, 
    openModalIcon: PropTypes.node,
}