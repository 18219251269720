import React, { useState } from 'react';
import {
    Form,
    DatePicker,
    Switch,
    Typography,
    Space,
} from 'antd';
import DragAndDrop from '../fields/DragAndDrop';
import { PlusOutlined } from '@ant-design/icons';
import { updateReport } from '../../utils/update-report';
import FormModal from '../modals/FormModal';
import PropTypes from 'prop-types';
import constants from '../../static/constants.json';

const { VENDOR_MANAGEMENT } = constants.OBJECT_TYPES;

const AddProductEvaluationForm = (props) => {
    const { vendorProducts, productIndex, setShouldUpdate } = props;
    const [form] = Form.useForm();
    const [isReevaluation, setIsReevaluation] = useState(true);
    const [modalTitle, setModalTitle] = useState("");
    const objectType = VENDOR_MANAGEMENT;

    const onOpen = () => { 
        setModalTitle(() => `${vendorProducts[productIndex].productName} ${!isReevaluation ? "Evaluation" : "Reevaluation"} Form`)
    }

    const onSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                form.resetFields();
                if (isReevaluation) { values.productEvaluationType = "Reevaluation" }
                else { values.productEvaluationType = "Evaluation" }
                values.evaluationTarget = "PRODUCT";
                values.productEvaluationHistory = vendorProducts[productIndex].productEvaluationHistory;
                values.vendorProducts = vendorProducts;
                values.isNewProduct = false;
                values.hasProductEvaluation = true;
                values.productIndex = productIndex;
                updateReport(values, vendorProducts[productIndex].vendorId, objectType, setShouldUpdate);
            })
            .catch((err) => {
                console.error({FormValidation: "FAILURE", message: err});
            });
    }

    const onClear = () => {
        form.resetFields();
    }

    const modalForm = {
        title: modalTitle,
        onSubmit: onSubmit,
        onClear: onClear,
        onOpen: onOpen,
    }

    const openButton = {
        type: "text",
        icon: <PlusOutlined />,
        shape: "circle",
        style: {
            marginLeft: "5px",
            marginRight: "5px",
        },
        tooltip: {
            title: "Add Product Evaluation",
        }
    }

    return (
        <>
            <FormModal openButton={openButton} modalForm={modalForm}>
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Form.Item
                        name="reportType"
                    >
                        <Space direction="horizontal"><Typography>Evaluation</Typography><Switch onClick={() => setIsReevaluation(!isReevaluation)} checked={isReevaluation} /><Typography>Reevaluation</Typography></Space>
                    </Form.Item>
                    <Form.Item
                        name="productEvaluationDate"
                        label={`Product ${!isReevaluation ? "Evaluation" : "Reevaluation"} Date`}
                        rules={[
                            {
                                required: true,
                                message: `${!isReevaluation ? "Evaluation" : "Reevaluation"} date required`
                            },
                        ]}
                    >
                        <DatePicker />
                    </Form.Item>
                    <Form.Item
                        name="productEvaluationReport"
                        label={`${!isReevaluation ? "Evaluation" : "Reevaluation"} Report`}
                        rules={[
                            {
                                required: true,
                                message: `${!isReevaluation ? "Evaluation" : "Reevaluation"} report PDF required`
                            },
                        ]}
                    >
                        <DragAndDrop
                            className="modal-element"
                            accept="application/pdf"
                            beforeUpload={() => false}
                        />
                    </Form.Item>
                </Form>
            </FormModal>
        </>
    );
}

export default AddProductEvaluationForm;

AddProductEvaluationForm.propTypes = {
    vendorProducts: PropTypes.array,
    productIndex: PropTypes.number,
    setShouldUpdate: PropTypes.func,
};