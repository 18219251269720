import axios from 'axios';
import { message } from 'antd';
import Auth from '@aws-amplify/auth';
import { TRUST_CENTER_USER_API } from '../static/endpoints';
import constants from '../static/constants.json';

const {
    ADMIN_GROUP, PRODUCT_RELEASE_GROUP, IMS_GROUP,
    ARTIFACT_GROUP, NOTES_GROUP, VENDOR_GROUP,
} = constants.GROUPS;

export const createUser = async (values) => {
    const key = 'updatable';
    message.loading({
        content: "loading...",
        top: 100,
        duration: 0,
        key
    });

    const formData = new FormData();
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();

    formData.append('email', values.email);
    formData.append(ADMIN_GROUP, values.admin);
    formData.append(PRODUCT_RELEASE_GROUP, values.productRelease);
    formData.append(IMS_GROUP, values.ims);
    formData.append(ARTIFACT_GROUP, values.artifact);
    formData.append(NOTES_GROUP, values.notes);
    formData.append(VENDOR_GROUP, values.vendor);

    try {
        const response = await axios.post(
            TRUST_CENTER_USER_API,
            formData,
            {
                headers: {
                    "Authorization": token,
                }
            }
        );
        message.success({
            content: "Success!",
            top: 100,
            duration: 1.5,
            key
        });
        console.log({Response: response});
    } catch (error) {
        console.error({
            CreateUserCall: "FAILED",
            message: error,
        })
        message.error({
            content: "Error!",
            top: 100,
            duration: 1.5,
            key
        });
    }
}