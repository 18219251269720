import { Divider } from 'antd';

const Footer = () => {
    return (
        <footer className="app-footer">
            <Divider orientation='left' />
        </footer>
    )
}

export default Footer;