import UserTable from '../components/tables/UserTable';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Space, Tabs } from 'antd';
import { useLocation, useHistory, useParams, generatePath } from 'react-router-dom';
import { reportsTabPath, usersTabPath } from '../static/routes';
import { GroupsContext } from '../contexts/GroupsContext';
import VendorTab from '../components/tabs/VendorTab';
import ReleaseNotesTab from '../components/tabs/ReleaseNotesTab';
import ImsTab from '../components/tabs/ImsTab';
import ProductReleaseTab from '../components/tabs/ProductReleaseTab';
import ArtifactsTab from '../components/tabs/ArtifactsTab';
import constants from '../static/constants.json';
import { useAuthenticator } from '@aws-amplify/ui-react';

const {
    ADMIN_GROUP, PRODUCT_RELEASE_GROUP, IMS_GROUP,
    ARTIFACT_GROUP, NOTES_GROUP, VENDOR_GROUP,
} = constants.GROUPS;

const ReportPage = () => {
    const [tableKeyState, setTableKeyState] = useState("");
    const page = useRef(null);
    let history = useHistory();
    let location = useLocation();
    let { reportType } = useParams();
    const { route: authRoute } = useAuthenticator(context => [context.route])
    const { groups } = useContext(GroupsContext);
    let isAdmin = false;
    if (groups.includes(ADMIN_GROUP)) {
        isAdmin = true;
    }

    const fullItemList = [
        {
            label: "Product Release",
            group: PRODUCT_RELEASE_GROUP,
            key: "productRelease",
            children: <ProductReleaseTab isAdmin={ isAdmin } />

        },
        {
            label: "IMS",
            group: IMS_GROUP,
            key: "imsDocuments",
            children: <ImsTab isAdmin={ isAdmin } />
        },
        {
            label: "Artifacts",
            group: ARTIFACT_GROUP,
            key: "artifactDocuments",
            children: <ArtifactsTab isAdmin={ isAdmin } />
        },
        {
            label: "Release Notes",
            group: NOTES_GROUP,
            key: "releaseNotes",
            children: <ReleaseNotesTab isAdmin={ isAdmin } />
        },
        {
            label: "Vendor Management",
            group: VENDOR_GROUP,
            key: "vendorManagement",
            children: <VendorTab isAdmin={ isAdmin } />
        },
        {
            label: "Users",
            group: "Users",
            key: "users",
            children: <UserTable />
        }
    ]

    useEffect(() => {
        if (authRoute === "authenticated") {
            page.current.hidden = false;
        }
        else {
            page.current.hidden = true;
        }

    }, [authRoute, page]);

    useEffect(() => {
        if (location.pathname.includes(usersTabPath)) {
            setTableKeyState(usersTabPath.slice(1));
        }
        else {
            setTableKeyState(reportType);
        }
    }, [location.pathname, reportType])

    const onTabChange = (key) => {
        if (key === 'users') {
            history.push(usersTabPath);
        } else {
            history.push(generatePath(reportsTabPath, {
                reportType: key,
            }))
        }
    }

    const constructItemList = () => {
        if (isAdmin === true) {
            fullItemList.forEach(item => {
                delete item.group;
            });
            return fullItemList
        }
        fullItemList.pop(); // Remove Users tab for non-admins
        const resultList = fullItemList.filter(item => {
            return groups.includes(item.group)
        });
        resultList.forEach(item => {
            delete item.group;
        });
        return resultList
    }

    const itemList = constructItemList();

    return (
        <div className="hidden-page" ref={page}>
            <Space className="report-page-space" direction='vertical' width='100%'>
                <Tabs
                    onChange={ onTabChange }
                    type="card"
                    defaultActiveKey={ tableKeyState }
                    activeKey={ tableKeyState }
                    items={itemList}
                />
            </Space>
        </div>
    );
}

export default ReportPage;