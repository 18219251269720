import { useState, useEffect } from 'react';

/**
 * @function
 * @name useLoading
 *
 * @param {Object} loadingObj The object data is being loaded into
 *
 * @returns {boolean} Whether data has been loaded into the object
 */
export const useLoading = (loadingObj) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!loadingObj) {
            setIsLoading(() => true);
        } else {
            setIsLoading(() => false);
        }
    }, [loadingObj]);

    return isLoading;
}