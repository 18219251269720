import { message } from 'antd';
import { createReport } from './reportUtils';

/**
 * @callback
 * @function setShouldUpdate
 * @param {boolean} shouldUpdate The flag determines if the front end needs to update or not
 */

/**
 * Sends a request to the API to create and store a new report
 * @async
 * @function postReport
 *
 * @param {Object} values The values of the form fields being submitted
 * @param {string} objectType The report code used to specify what type of report is being posted
 * @param {updateCallback} setShouldUpdate Callback function that will flag the front end to update after data has been posted
 * @returns {Promise} Promise that represents the response of the API POST call
 */
export const postReport = async (values, objectType, setShouldUpdate) => {
    const PRE_RELEASE = false;
    const RELEASE = true;

    const key = 'updatable';
    message.loading({
        content: "loading...",
        top: 100,
        duration: 0,
        key
    });

    const formData = new FormData();

    switch (objectType) {
        case 'release':
            console.log({TestingReport: values.testingReport});
            let testingReports = [];
            testingReports.push(values.testingReport)
            let zipDownloads = [];
            if (!!values.zipFilename) { zipDownloads.push(values.zipFilename) }

            if (Array.isArray(values.sponsorTestingReports) === true && values.sponsorTestingReports.length > 0) {
                values.sponsorTestingReports.forEach(testingReport => {
                    testingReports.push(testingReport);
                })
            }
            if (Array.isArray(values.sponsorZipFilenames) && values.sponsorZipFilenames.length > 0) {
                values.sponsorZipFilenames.forEach(zipDownload => {
                    zipDownloads.push(zipDownload);
                })
            }

            formData.append('objectType', objectType);
            formData.append('product', values.product);
            formData.append('releaseDate', values.releaseDate.toISOString());
            formData.append('releaseNumber', values.releaseNumber);
            formData.append('testingReports', JSON.stringify(testingReports));
            formData.append('signedRelease', values.signedRelease.file);
            formData.append('zipDownloads', JSON.stringify(zipDownloads));
            formData.append('installationQualification', values.installationQualification?.file);
            break;
        case 'ims':
            formData.append('objectType', objectType);
            formData.append('name', values.name);
            formData.append('documentType', values.documentType);
            formData.append('version', values.version);
            formData.append('effectiveDate', values.effectiveDate.toISOString());
            formData.append('imsDocument', values.imsDocument.file);
            formData.append('versionId', values.versionId);
            break;
        case 'artifact':
            formData.append('objectType', objectType);
            formData.append('name', values.name);
            formData.append('department', values.department);
            formData.append('version', values.version);
            formData.append('effectiveDate', values.effectiveDate.toISOString());
            formData.append('artifactDocument', values.artifactDocument.file);
            formData.append('versionId', values.versionId);
            break;
        case 'notes':
            formData.append('objectType', objectType);
            formData.append('productName', values.productName);
            if (!values.formType || values.formType === PRE_RELEASE) {
                formData.append('preReleaseForm', true);
                formData.append('preReleaseNumber', values.preReleaseNumber);
                formData.append('preReleaseDate', values.preReleaseDate.toISOString());
                formData.append('preReleaseNotes', values.preReleaseNotes.file);
            } else if (values.formType === RELEASE) {
                formData.append('preReleaseForm', false);
                formData.append('releaseNumber', values.releaseNumber);
                formData.append('releaseDate', values.releaseDate.toISOString());
                formData.append('releaseNotes', values.releaseNotes.file);
            }
            break;
        case 'vendor':
            let hasNextEvaluationDate
            const hasVendorEvaluation = values.addVendorEvaluationSwitch;
            hasNextEvaluationDate = !!values.nextVendorEvaluationDate;

            formData.append('objectType', objectType);
            formData.append('vendorName', values.vendorName);
            formData.append('isNewVendor', values.isNewVendor);
            if (values.isNewVendor === true && values.dateOfService) {
                formData.append('dateOfService', values.dateOfService.toISOString())
            }
            formData.append('hasVendorEvaluation', hasVendorEvaluation);
            if (hasVendorEvaluation === true) {
                formData.append('evaluationTarget', values.evaluationTarget)
                formData.append('vendorEvaluationDate', values.vendorEvaluationDate.toISOString())
                formData.append('vendorEvaluationReport', values.vendorEvaluationReport.file);
            }
            if (hasNextEvaluationDate === true) {
                formData.append('nextVendorEvaluationDate', values.nextVendorEvaluationDate.toISOString())
            }
            break;
        // case 'configuration':
        //     formData.append('objectType', objectType);
        //     formData.append('customerName', values.customerName)
        //     break;
        default:
            console.warn(`${objectType} is not supported at this time.`)
            break;
    }

    try {
        const response = await createReport(formData);
        message.success({
            content: "Success!",
            top: 100,
            duration: 1.5,
            key
        })
        setShouldUpdate(() => true);
        return response;
    } catch (err) {
        console.error("error posting data:", err, err.response);
        message.error({
            content: "Error!",
            top: 100,
            duration: 1.5,
            key
        })
        return err.response;
    }
};