import {
    Form,
    DatePicker,
    Switch,
    Typography,
    Space,
    Tooltip,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import DragAndDrop from '../fields/DragAndDrop';
import React, { useState } from 'react';
import { updateReport } from '../../utils/update-report';
import FormModal from '../modals/FormModal';
import PropTypes from 'prop-types';
import constants from '../../static/constants.json';

const { VENDOR_MANAGEMENT } = constants.OBJECT_TYPES;


const AddVendorEvaluationForm = (props) => {
    const { vendorEvaluationHistory, setShouldUpdate, objectId, } = props;
    const [form] = Form.useForm();
    const [isReevaluation, setIsReevaluation] = useState(true);
    const [vendorId, setVendorId] = useState(props.objectId);

    const objectType = VENDOR_MANAGEMENT;

    const onOpen = () => { 
        setVendorId(() => props.objectId);
    }

    const onSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                form.resetFields();
                if (isReevaluation) { values.vendorEvaluationType = "Reevaluation" }
                else { values.vendorEvaluationType = "Evaluation" }
                values.evaluationTarget = "VENDOR";
                values.vendorEvaluationHistory = vendorEvaluationHistory;
                console.log({id: objectId})
                updateReport(values, vendorId, objectType, setShouldUpdate);
                setShouldUpdate(true);
            })
            .catch((err) => {
                console.error({FormValidation: "FAILURE", message: err});
            })
    }

    const onClear = () => {
        form.resetFields();
    }

    const modalForm = {
        onSubmit: onSubmit,
        onClear: onClear,
        onOpen: onOpen,
        title: `${props.vendorName} ${!isReevaluation ? "Evaluation" : "Reevaluation"} Form`,
    }

    const openButton = {
        className: "icon-btn",
        type: "text",
        icon: <PlusOutlined />,
        shape: "circle",
        tooltip: {
            title: "Add Vendor Evaluation",
        }
    }

    return (
        <>
            <FormModal openButton={openButton} modalForm={modalForm}>
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Form.Item
                        name="reportType"
                    >
                        <Space direction="horizontal"><Typography>Evaluation</Typography><Switch onClick={() => setIsReevaluation(!isReevaluation)} checked={isReevaluation} /><Typography>Reevaluation</Typography></Space>
                    </Form.Item>
                    <Form.Item
                        name="vendorEvaluationDate"
                        label={`Vendor ${!isReevaluation ? "Evaluation" : "Reevaluation"} Date`}
                        rules={[
                            {
                                required: true,
                                message: `${!isReevaluation ? "Evaluation" : "Reevaluation"} date required`
                            },
                        ]}
                    >
                        <DatePicker />
                    </Form.Item>
                    <Form.Item
                        name="vendorEvaluationReport"
                        label={`${!isReevaluation ? "Evaluation" : "Reevaluation"} Report`}
                        rules={[
                            {
                                required: true,
                                message: `${!isReevaluation ? "Evaluation" : "Reevaluation"} report PDF required`
                            },
                        ]}
                    >
                        <DragAndDrop
                            className="modal-element"
                            accept="application/pdf"
                            beforeUpload={() => false}
                        />
                    </Form.Item>

                    <Tooltip
                        title="You can change this later"
                        mouseEnterDelay={0.5}
                    >
                        <Form.Item
                            name="nextVendorEvaluationDate"
                            label="Next Evaluation Date (Optional)"
                        >
                            <DatePicker />
                        </Form.Item>
                    </Tooltip>
                </Form>
            </FormModal>
        </>
    );
}

export default AddVendorEvaluationForm;

AddVendorEvaluationForm.propTypes = {
    vendorEvaluationHistory: PropTypes.array,
    setShouldUpdate: PropTypes.func,
    objectId: PropTypes.string,
}