import { Tag, Tooltip } from 'antd'
import React, { useState } from 'react';
import { LoadingOutlined, CloseOutlined } from '@ant-design/icons';
import ReportAccessModifierForm from '../forms/ReportAccessModifierForm';
import { removeUserFromGroup } from '../../utils/admin-utils';
import { products } from '../../static/products';

const AccessLevelTag = (props) => {
    const {
        reportAccessIcon,
        closeAccessTitle,
        changeAccessTitle,
        accessLevel,
        username,
        tagKey,
        setShouldFetch
    } = props;
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(true);

    return (
        <div>
            {
                visible !== true ? (
                    null
                ) : (
                    accessLevel === "Admin" ? (
                        <Tag
                            closable
                            color={products.flatironVessel.primaryColor + '50'}
                            closeIcon={
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: "center",
                                        padding: '0 0 0 7px',
                                        height: "24px",
                                    }}
                                >
                                    {
                                        loading === true ? (
                                            <LoadingOutlined />
                                        ) : (
                                            <Tooltip title={closeAccessTitle}>
                                                <CloseOutlined
                                                    style={{
                                                        color: '#000000D9',
                                                    }}
                                                />
                                            </Tooltip>
                                        )
                                    }
                                </div>
                            }
                            key={tagKey}
                            onClose={() => {
                                setLoading(() => true);
                                removeUserFromGroup(username, accessLevel)
                                    .then(() => {
                                        console.log({RemoveUserFromGroup: "SUCCESS"});
                                        setLoading(() => false);
                                    })
                                    .then(() => {
                                        setVisible(() => false);
                                    })
                                    .catch((error) => {
                                        console.error({RemoveUserFromGroup: "FAILED", Message: error});
                                    })
                            }}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-after',
                                margin: '4px',
                                padding: '0 7px',
                                fontSize: '14px',
                                color: '#000000D9',
                                border: "1px solid " + products.flatironVessel.primaryColor,
                            }}
                        >
                            { accessLevel }
                        </Tag>

                    ) : (
                        <Tag
                            size="small"
                            closable
                            icon={reportAccessIcon}
                            closeIcon={
                                <>
                                    {
                                        loading === true ? (
                                            <LoadingOutlined />
                                        ) : (
                                            <Tooltip title={closeAccessTitle}>
                                                <CloseOutlined />
                                            </Tooltip>
                                        )
                                    }
                                </>
                            }
                            key={tagKey}
                            onClose={() => {
                                setLoading(() => true);
                                removeUserFromGroup(username, accessLevel)
                                    .then(() => {
                                        console.log({RemoveUserFromGroup: "SUCCESS"});
                                        setLoading(() => false);
                                    })
                                    .then(() => {
                                        setVisible(() => false);
                                    })
                                    .catch((error) => {
                                        console.error({RemoveUserFromGroup: "FAILED", Message: error});
                                    })
                            }}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: '4px',
                            }}
                        >
                            <Tooltip title={changeAccessTitle}>
                                <ReportAccessModifierForm
                                    accessLevel={accessLevel}
                                    username={username}
                                    setShouldFetch={setShouldFetch}
                                >
                                    { accessLevel }
                                </ReportAccessModifierForm>
                            </Tooltip>
                        </Tag>
                    )
                )
            }
        </div>
    );
}

export default AccessLevelTag;