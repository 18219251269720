import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { GroupsContext } from '../../contexts/GroupsContext';
import { SignedInRootPath } from '../../static/routes';
import { UserContext } from '../../contexts/UserContext';
import constants from '../../static/constants.json';
import { useAuthenticator } from '@aws-amplify/ui-react';

const {
    ADMIN_GROUP, PRODUCT_RELEASE_GROUP, IMS_GROUP,
    ARTIFACT_GROUP, NOTES_GROUP, VENDOR_GROUP,
} = constants.GROUPS

const SignIn = (props) => {
    const [redirect, setRedirect] = useState();
    const { groups } = useContext(GroupsContext);
    const { user } = useContext(UserContext)
    const { route: authRoute } = useAuthenticator(context => [context.route])

    useEffect(() => {
        if (authRoute === "authenticated" && user) {
            if (groups.includes(ADMIN_GROUP)) setRedirect(() => <Redirect to={SignedInRootPath("productRelease")} />);
            else if (groups.includes(PRODUCT_RELEASE_GROUP)) setRedirect(() => <Redirect to={SignedInRootPath("productRelease")} />);
            else if (groups.includes(IMS_GROUP)) setRedirect(() => <Redirect to={SignedInRootPath("imsDocuments")} />);
            else if (groups.includes(ARTIFACT_GROUP)) setRedirect(() => <Redirect to={SignedInRootPath("artifactDocuments")} />);
            else if (groups.includes(NOTES_GROUP)) setRedirect(() => <Redirect to={SignedInRootPath("releaseNotes")} />);
            else if (groups.includes(VENDOR_GROUP)) setRedirect(() => <Redirect to={SignedInRootPath("vendorManagement")} />);
        }
    }, [groups, authRoute, user]);

    return (
        <div>
            {
                authRoute === "signIn" || authRoute === "resetPassword" ? (
                    null
                ) : (
                    <>
                        { redirect }
                    </>
                )
            }
        </div>
    );
}

export default SignIn;