import React from 'react';

const FieldGrouper = (props) => {
    console.log({Children: props.children});
    return (
        <>
            <fieldset style={props.fieldsetStyle}>
                <legend style={props.legendStyle}>{props.title}</legend>
                {props.children}
            </fieldset>
        </>
    );
}

export default FieldGrouper;