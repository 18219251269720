import ProtectedRoute from './ProtectedRoute';
import {
    Route,
    Switch
} from 'react-router-dom';
import ReportPage from '../../pages/ReportPage';
import ReportView from '../../pages/ReportView';
import ErrorPage from '../../pages/ErrorPage';
import SignIn from '../auth/SignIn';
import {
    rootPath,
    reportsTabPath,
    usersTabPath,
    errorCodePath,
    showReportPath
} from '../../static/routes';

const RouteSwitch = () => {

    return (
        <Switch>
            <ProtectedRoute
                exact path={ rootPath }
                route={ <SignIn /> }
            />
            <ProtectedRoute
                path={ reportsTabPath }
                route={ <ReportPage className="report-page" /> }
            />
            <ProtectedRoute
                path={ usersTabPath }
                route={ <ReportPage className="report-page" /> }
            />
            <ProtectedRoute
                exact path={ showReportPath }
                route={ <ReportView /> }
            />
            <Route
                path={ errorCodePath }
                component={ ErrorPage }
            />
        </Switch>
    );
}

export default RouteSwitch;

