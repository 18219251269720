import { useState, useEffect } from 'react';
import { postReport } from '../utils/post-report';
import { updateReport } from '../utils/update-report';

/**
 * @callback updateCallback
 * @name setShouldUpdate
 * @param {boolean} shouldUpdate The flag to let the front end know that new data has been uploaded to the backend
 */

/**
 *
 * @param {Object} form The form object that is being submitted
 * @param {string} objectType The object type code used by the backend to filter the submitted data
 * @param {updateCallback} setShouldUpdate Callback to tell the front end whether new data has been submitted
 * @param {Object} otherValues Other values that may need to be included in the POST
 * @returns {Array} A boolean to flag whether the form is ready to be submitted or not
 */
export const useSubmitForm = (submitFormParams) => {
    const { form, objectType, setShouldUpdate, itemId } = submitFormParams;
    const [shouldSubmitForm, setShouldSubmitForm] = useState(false);
    const [otherValues, setOtherValues] = useState({});

    useEffect(() => {
        if (shouldSubmitForm === true) {
            form
                .validateFields()
                .then((values) => {
                    form.resetFields();
                    if (otherValues) {
                        values = {
                            ...values,
                            ...otherValues,
                        }
                    }
                    console.log({Values: values, ItemId: itemId});
                    if (!itemId) {
                        postReport(values, objectType, setShouldUpdate);
                    } else {
                        updateReport(values, itemId, objectType, setShouldUpdate);
                    }
                })
                .then(() => {
                    setShouldSubmitForm(() => false);
                })
                .then(() => {
                    setShouldUpdate(() => true);
                })
                .catch((err) => {
                    console.error({FormValidation: "FAILED", message: err});
                })
        }
    }, [form, objectType, setShouldUpdate, shouldSubmitForm, otherValues, itemId]);

    return [setShouldSubmitForm, setOtherValues];
}