import React, { useState } from 'react';
import DragAndDrop from '../fields/DragAndDrop';
import {
    Typography,
    Input,
    Select,
    DatePicker,
    Form,
    Switch,
} from 'antd';
import { useSubmitForm } from '../../lib/useSubmitForm';
import FormModal from '../modals/FormModal';
import PropTypes from 'prop-types';
import constants from '../../static/constants.json';
import { products } from '../../static/products';

const { NOTES } = constants.OBJECT_TYPES;

const { Option } = Select;

const productNameOptions = [
    products.flatironVessel.title,
    products.flatironClinicalPipe.title,
    products.flatironSourceUpload.title,
];

const PRE_RELEASE = {
    type: false,
    name: "preRelease",
    label: "Pre-Release",
}
const RELEASE = {
    type: true,
    name: "release",
    label: "Release",
};

const getFormInfo = (formType, info) => {
    try {
        if (formType === PRE_RELEASE.type) {
            return PRE_RELEASE[info]
        } else {
            return RELEASE[info]
        }
    } catch (err) {
        console.error(info + " is not a valid string:", err);
    }
}

const NotesForm = ({ setShouldUpdate }) => {
    const [ form ] = Form.useForm()
    const [formType, setFormType] = useState(PRE_RELEASE.type);
    const objectType = NOTES;
    let hookParams = {
        form,
        objectType,
        setShouldUpdate,
    }

    const [setShouldSubmitForm] = useSubmitForm(hookParams);

    const onOpen = () => {
        setFormType(PRE_RELEASE.type)
    }

    const onSubmit = () => {
        setShouldSubmitForm(() => true);
    };

    const onClear = () => {
        form.resetFields();
        setFormType(PRE_RELEASE.type);
    };

    const renderOptions = (options) => {
        return (
            options.map((option, idx) => {
                return <Option key={option + idx} value={ option }>{ option }</Option>
            })
        );
    }

    const switchFormType = () => {
        setFormType(currFormType => !currFormType);
    }

    const noteFormStates = {
        productName: {
            name: "productName",
            label: "Product Name",
            rules: [
                {
                    required: true,
                    message: "Product name required"
                }
            ],
        },
        number: {
            name: `${getFormInfo(formType, "name")}Number`,
            label: "Number",
            rules: [
                {
                    required: true,
                    message: `${getFormInfo(formType, "label")} number required`
                },
            ]
        },
        date: {
            name: `${getFormInfo(formType, "name")}Date`,
            label: "Date",
            rules: [
                {
                    required: true,
                    message: `${getFormInfo(formType, "label")} date required`
                }
            ],
        },
        notes: {
            name: `${getFormInfo(formType, "name")}Notes`,
            label: `${getFormInfo(formType, "label")} Notes`,
            rules: [
                {
                    required: true,
                    message: `${getFormInfo(formType, "label")} notes PDF required`
                }
            ],
            fileUploadProps: {
                className: "modal-element",
                accept: "application/pdf",
                beforeUpload: () => false,
            }
        }
    }

    const modalForm = {
        onSubmit,
        onClear,
        onOpen,
        title: "Notes Form",
    }

    const openButton = {
        text: "Add Notes"
    }

    return (
        <>
            <FormModal modalForm={modalForm} openButton={openButton}>
                <Form
                    form={ form }
                    layout="vertical"
                    name="notes_form_in_modal"
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "horizontal",
                            alignItems: "center",
                            marginBottom: "24px",
                        }}
                    >
                        <Typography style={{ marginRight: "10px" }}>Pre-Release</Typography>
                        <Form.Item
                            name="formType"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "0px",
                            }}
                        >
                            <Switch
                                className="form-toggle"
                                autoFocus
                                checked={formType}
                                onChange={switchFormType}
                            />
                        </Form.Item>
                        <Typography style={{ marginLeft: "10px" }}>Release</Typography>
                    </div>
                    <Form.Item
                        {...noteFormStates.productName}
                    >
                        <Select>
                            { renderOptions(productNameOptions) }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        {...noteFormStates.number}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        {...noteFormStates.date}
                    >
                        <DatePicker />
                    </Form.Item>
                    <Form.Item
                        {...noteFormStates.notes}
                    >
                        <DragAndDrop
                            {...noteFormStates.notes.fileUploadProps}
                        />
                    </Form.Item>
                </Form>
            </FormModal>
        </>
    );
}

NotesForm.propTypes = {
    setShouldUpdate: PropTypes.func,
}

export default NotesForm;