import {
    Form,
    Input,
    DatePicker,
    Switch,
    Tooltip,
} from 'antd';
import React, { useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import DragAndDrop from '../fields/DragAndDrop';
import { postReport } from '../../utils/post-report';
import FormModal from '../modals/FormModal';
import PropTypes from 'prop-types';
import constants from '../../static/constants.json';

const { VENDOR_MANAGEMENT } = constants.OBJECT_TYPES;

const AddVendorForm = ({ setShouldUpdate }) => {
    const [form] = Form.useForm();
    const [addVendorEvaluation, setAddVendorEvaluation] = useState(false);
    const objectType = VENDOR_MANAGEMENT;

    const onSubmit = () => {
        form
            .validateFields()
            .then((formValues) => {
                form.resetFields();
                formValues.isNewVendor = true;
                formValues.evaluationTarget = "VENDOR";
                console.log({formValues: formValues});
                postReport(formValues, objectType, setShouldUpdate)
                setShouldUpdate(true);
                setAddVendorEvaluation(() => false);
            }
        )
    }

    const onClear = () => {
        form.resetFields();
        setAddVendorEvaluation(() => false);
    }
    
    const modalForm = {
        onSubmit: onSubmit,
        onClear: onClear,
        title: "Vendor Form",
    }

    const openButton = {
        text: "Add Vendor"
    }

    return (
        <>
            <FormModal modalForm={modalForm} openButton={openButton}>
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Form.Item
                        name="vendorName"
                        label="Vendor Name"
                        rules={[
                            {
                                required: true,
                                message: "Vendor name required"
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="dateOfService"
                        label="Date Of Service"
                    >
                        <DatePicker />
                    </Form.Item>
                    <Form.Item
                        name="addVendorEvaluationSwitch"
                        label="Add Vendor Evaluation"
                        initialValue={false}
                    >
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            defaultChecked={false}
                            onChange={() => setAddVendorEvaluation(currAddVendorEvaluation => !currAddVendorEvaluation)}
                            checked={addVendorEvaluation}
                        />
                    </Form.Item>
                    {
                        addVendorEvaluation !== true ? (
                            null
                        ) : (
                            <>
                                <Form.Item
                                    name="vendorEvaluationDate"
                                    label={`Vendor Evaluation Date`}
                                    rules={[
                                        {
                                            required: addVendorEvaluation,
                                            message: `Evaluation date required`
                                        },
                                    ]}
                                >
                                    <DatePicker />
                                </Form.Item>
                                <Form.Item
                                    name="vendorEvaluationReport"
                                    label={`Evaluation Report`}
                                    rules={[
                                        {
                                            required: addVendorEvaluation,
                                            message: `Evaluation report PDF required`
                                        },
                                    ]}
                                >
                                    <DragAndDrop
                                        className="modal-element"
                                        accept="application/pdf"
                                        beforeUpload={() => false}
                                    />
                                </Form.Item>
                                <Tooltip 
                                    title="You can change this later"
                                    mouseEnterDelay={0.5}
                                >
                                    <Form.Item
                                        name="nextVendorEvaluationDate"
                                        label="Next Evaluation Date (Optional)"
                                    >
                                            <DatePicker />
                                    </Form.Item>
                                </Tooltip>
                            </>
                        )
                    }
                </Form>
            </FormModal>
        </>
    );
}

export default AddVendorForm;

AddVendorForm.propTypes = {
    setShouldUpdate: PropTypes.func,
}