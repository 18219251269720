/**
 * Creates a list of column objects that an Ant Design table can use for its column property
 * @function columnCreator
 *
 * @param {Object} props
 * @param {Array} props.keys An array of keys that are assigned to the resulting column objects
 * @param {Array} props.titles An array of strings that will become the titles of the resulting column objects
 * @param {Array} props.dataIndexes An array of strings that will become the dataIndex names of the resulting column objects
 * @param {Array} props.widths An array of strings that represent the column widths of the resulting column objects 
 * @returns {Array}
 */
export function columnCreator(props) {
    const { keys, titles, dataIndexes, widths } = props;
    let columnWidths = widths;
    if (typeof widths === "string" && widths.includes(" ") === false) {
        columnWidths = [];
        for (let i = 0; i < titles.length; i++) {
            columnWidths.push(widths);
        }
    }
    const columns = keys.map((key, idx) => {
        return {
            key,
            title: titles[idx],
            dataIndex: dataIndexes[idx],
            width: columnWidths[idx],
        }
    })
    return columns;
}