import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons';
import DragAndDrop from '../fields/DragAndDrop';
import {
    Input,
    DatePicker,
    Form,
    Typography,
} from 'antd';
import { useSubmitForm } from '../../lib/useSubmitForm';
import FormModal from '../modals/FormModal';
import constants from '../../static/constants.json';

const { ARTIFACT } = constants.OBJECT_TYPES;

const ImsAddNewVersionForm = ({ setShouldUpdate, tableData }) => {
    const [ form ] = Form.useForm()
    const [name, setName] = useState(tableData.name);
    const objectType = ARTIFACT;
    let hookParams = {
        form,
        objectType,
        setShouldUpdate,
    }

    const [setShouldSubmitForm, setOtherValues] = useSubmitForm(hookParams);

    const onOpen = () => {
    };

    const onSubmit = () => {
        setOtherValues({ versionId: tableData.versionId });
        setShouldSubmitForm(() => true);
    };

    const onClear = () => {
        form.resetFields();
    };

    const openButton = {
        className: "icon-btn",
        type: "text",
        icon: <PlusOutlined />,
        shape: "circle",
        tooltip: {
            title: "Add new document version"
        }
    }

    const modalForm = {
        title: "Artifact Form",
        onSubmit,
        onClear,
        onOpen,
    }

    return (
        <>
            <FormModal openButton={openButton} modalForm={modalForm}>
                <Form 
                    form={ form }
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                            {
                                required: true,
                                message: "Name required"
                            },
                        ]}
                        initialValue={name}
                    >
                        <Input value={name} onChange={(event) => setName(() => event.target.value)} />
                    </Form.Item>
                    <Typography.Title
                        level={5}
                        style={{
                            marginBottom: "16px",
                        }}
                    >
                        {`Version of: ${tableData.name}`}
                    </Typography.Title>
                    <Form.Item
                        name="department"
                        label="Department"
                        rules={[
                            {
                                required: true,
                                message: "Department required"
                            },
                        ]}
                        initialValue={tableData.department}
                    >
                        <Input disabled={true} value={tableData.department} />
                    </Form.Item>
                    <Form.Item
                        name="effectiveDate"
                        label="Effective Date"
                        rules={[
                            {
                                required: true,
                                message: "Effective date required"
                            },
                        ]}
                    >
                        <DatePicker />
                    </Form.Item>
                    <Form.Item
                        name="version"
                        label="Version"
                        rules={[
                            {
                                required: true,
                                message: "Version required"
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="artifactDocument"
                        label="Artifact Document"
                        rules={[
                            {
                                required: true,
                                message: "Artifact document PDF required"
                            },
                        ]}
                    >
                        <DragAndDrop
                            className="modal-element"
                            accept="application/pdf"
                            beforeUpload={() => false}
                        />

                    </Form.Item>
                </Form>
            </FormModal>
        </>
    );
}

export default ImsAddNewVersionForm;